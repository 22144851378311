import React, { useRef } from "react";
import { useState } from "react";
import icon from "../../assets/Success.svg";
import icon7 from "../../assets/Cancelwhite.svg";
import icon6 from "../../assets/Cancel.svg";
import axios from "axios";
import "../../Products/Catalog_Form/Catalogform.css";
import img1 from "../../assets/Img1.svg";
import img2 from "../../assets/Img2.svg";
import img3 from "../../assets/Img3.svg";
import img4 from "../../assets/Upload.svg";
import img5 from "../../assets/Check1.svg";
import img6 from "../../assets/Trash.svg";
import img7 from "../../assets/Info.png";
import { BaseURL } from "../../BaseURL";
import ReactCaptcha from "../../Captcha/Captcha";

const Resumeform = ({ setResumeForm1, JobName }) => {
  const captchaRef = useRef();
  const captchaConfig = {
    type: 1,
    length: 6,
    font: {
      size: "40px",
      family: "Sora",
      color: "#2e2e2e",
    },
    back: {
      stroke: "#2e2e2e",
      solid: "#ffffff ",
    },
    cssClass: "custom-captcha",
    handleBlur: (e) => {
      handleBlur(e);
    },
    handleFocus: (e) => {
      handleFocus(e);
    },
    Inputcaptcha: "",
    Resultcaptcha: "1",
  };
  const [formValues, setFormValues] = useState({
    Full_Name: "",
    Email: "",
    Phone_Number: "",
    Resume: "",
    Intro_Video_Link: "",
    Message: "",
    JobName,
  });
  const [fileLabels, setFileLabels] = useState({
    Resume: "",
  });
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };

  const updateLabel = (event, inputId) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2097152 || selectedFile.size === 4096) {
        alert("File is too big!");
        return;
      } else if (selectedFile.name.split(".").pop() !== "pdf") {
        alert("Only PDF files are allowed");
        console.log(formValues);
        return;
      } else {
        const newLabels = { ...fileLabels };
        newLabels[inputId] = selectedFile.name || "Please upload the file";
        setFileLabels(newLabels);
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
          setFormValues({
            ...formValues,
            Resume: reader.result,
          });
          setFileUploaded(true);
        };
      }
    } else {
      alert("Please select a file");
      setFileLabels({
        Resume: "",
      });
      setFileUploaded(false);
    }
  };

  const clearFile = (field) => {
    setFileLabels((prevLabels) => ({
      ...prevLabels,
      [field]: "Upload a file",
    }));
    setFileUploaded(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowForm(false);
    setIsLoading(true);

    if (
      [
        formValues.Full_Name,
        formValues.Email,
        formValues.Resume,
        formValues.Phone_Number,
      ].some((field) => field?.trim() === "")
    ) {
      setIsLoading(false);
      setShowForm(true);
      alert("Please fill out required fields.");
      return;
    }
    if (captchaConfig.Inputcaptcha === captchaConfig.Resultcaptcha) {
      await axios.post(`${BaseURL}/api/v1/users/ResumeForm`, formValues, {
        header: {
          "content type": "application.json",
        },
      });
      // const responseData = response.data;
      setFormValues({
        Full_Name: "",
        Email: "",
        Resume: "",
        Phone_Number: "",
        Intro_Video_Link: "",
        Message: "",
      });
      setIsLoading(false);
      setShowPopup(true);
    }
    // else if (captchaConfig.Inputcaptcha !== captchaConfig.Resultcaptcha) {
    //   alert("Check Captcha")

    // }
    else {
      // console.log(captchaConfig.Inputcaptcha);
      // console.log(captchaConfig.Resultcaptcha);
      alert("Captcha verification failed. Please try again.");
      setIsLoading(false);
      setShowForm(true);
    }
  };

  const handleFocus = (e) => {
    const label = e.target.previousElementSibling;
    label.classList.add("Focused");
  };
  const handleBlur = (e) => {
    const label = e.target.previousElementSibling;
    if (!e.target.value) {
      label.classList.remove("Focused");
    }
  };

  const [info, setInfo] = useState(false);

  const handleInfo = () => {
    setInfo(!info);
  };
  return (
    <>
      {showForm && (
        <>
          <div
            className="Overlay2"
            onClick={() => {
              setResumeForm1(false);
            }}
          />
          <div className="Popup2">
            <div className="catalog_form">
              <div className="catalogform_heading">
                <img
                  src={icon7}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => {
                    setShowPopup(false);
                    setResumeForm1(false);
                  }}
                />
                <h3>SHARE US YOUR RESUME</h3>
                <h5>Our HR will contact you.</h5>
              </div>
              <form className="Catalogue_Form">
                <div className="Job_Text">
                  <h6>Hi, You’re applying for {JobName}</h6>
                </div>
                <div className="Resume_Holder">
                  <div className="Input_Holder">
                    <label className="Label">Full Name*</label>
                    <input
                      type="text"
                      name="Full_Name"
                      className="Input_Field"
                      value={formValues.Full_Name}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <div className="Input_Holder">
                    <label className="Label">Phone Number*</label>
                    <input
                      type="text"
                      name="Phone_Number"
                      className="Input_Field2"
                      value={formValues.Phone_Number}
                      onChange={(event) => {
                        handleChange(event);
                        handleNumChangeM(event);
                      }}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      required
                    />
                  </div>
                  <div className="Input_Holder">
                    <label className="Label">E-Mail*</label>
                    <input
                      type="email"
                      name="Email"
                      className="Input_Field"
                      value={formValues.Email}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      required
                    />
                  </div>

                  <label className="Resume_Box">
                    <div
                      className="Input_Holder"
                      style={{ borderBottom: "1px solid #dedede" }}
                    >
                      <label className="custom-file-label">Resume *</label>

                      {fileUploaded ? (
                        <>
                          <div className="File_Upload_Box">
                            <label className="custom-file-label">
                              {fileLabels.Resume}
                            </label>
                            <img
                              src={img5}
                              alt="Check"
                              style={{ marginRight: "20px", cursor: "default" }}
                              className="custom-file-icon"
                            />
                            <img
                              src={img6}
                              alt="Delete"
                              onClick={clearFile}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </>
                      ) : (
                        <img
                          src={img4}
                          alt="Upload Resume"
                          className="custom-file-icon"
                        />
                      )}

                      <input
                        type="file"
                        name="Resume"
                        className="form-input"
                        accept=".pdf"
                        onChange={(e) => {
                          updateLabel(e, "Resume");
                        }}
                      />
                    </div>
                    {fileUploaded ? (
                      <></>
                    ) : (
                      <>
                        <h6 className="Warning_text">
                          Only .pdf allowed with max size of 2MB
                        </h6>
                      </>
                    )}
                  </label>
                  <div className="Input_Holder">
                    <label className="Label">Intro-Video Link</label>
                    <input
                      type="text"
                      name="Intro_Video_Link"
                      className="Input_Field"
                      value={formValues.Intro_Video_Link}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {/* <img
                      className="Info_Button"
                      src={img7}
                      onClick={handleInfo}
                      onMouseOver={handleInfo}
                      onMouseLeave={handleInfo}
                    />
                    <div
                      className="Information"
                      style={{ display: info ? "block" : "none" }}
                    >
                      <p>Paste the link to your video introduction here</p>
                    </div> */}
                  </div>
                  <h6 className="Warning_text">
                    Paste the link to your video introduction here
                  </h6>

                  <div className="Input_Holder">
                    <label className="Label">Your message</label>
                    <textarea
                      name="Message"
                      className="Message"
                      value={formValues.Message}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </div>
                  <ReactCaptcha config={captchaConfig} ref={captchaRef} />
                  <div className="Submit_button">
                    {isLoading ? (
                      <>
                        <div className="Overlay2" />
                        <div className="Popup1">
                          <div className="Logo_Container">
                            <div className="Image">
                              <div className="Img1">
                                <img className="Spin1" src={img1} alt="" />
                              </div>
                              <div className="Img2">
                                <img className="Spin" src={img2} alt="" />
                              </div>
                              <div className="Img3">
                                <img src={img3} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="Form_Submit"
                          onClick={handleSubmit}
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {showPopup && (
        <>
          <div className="Overlay2" />
          <div className="Popup">
            <img
              src={icon6}
              alt="Close"
              className="CloseButton"
              onClick={() => {
                setShowPopup(false);
                setResumeForm1(false);
              }}
            />
            <div className="PContents">
              <img src={icon} alt="Popup" />
              <h3>Thank you!</h3>
              <p>Our HR will soon get in touch with you.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Resumeform;
