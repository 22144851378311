import React, { useContext, useState } from "react";
import { contactContext } from "./MyProvider";
export const useContact = () => {
  return useContext(contactContext);
};
const ContactProvider = ({ children }) => {
  const [productName, setProductName] = useState("");
  return (
    <contactContext.Provider value={{ productName, setProductName }}>
      {children}
    </contactContext.Provider>
  );
};

export default ContactProvider;
