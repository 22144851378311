import React, { useEffect, useRef, useState } from "react";
import cloudDownload from "../../assets/cloudDownload.svg";
import cloudDownload1 from "../../assets/cloudDownload1.svg";
import "./Page.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../Catalog_Form/Catalogform";
import Trialform from "../Trial_form/Trialform";
import ReactPlayer from "react-player/lazy";
import India from "../../assets/India.svg";
import { useContact } from "../../context/ContactProvider";
import { Waypoint } from "react-waypoint";

const FaceTech = () => {
  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [isTrialHovered, setIsTrialHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);
  const [trailForm1, setTrialForm1] = useState(false);

  const { setProductName } = useContact();
  useEffect(() => {
    setProductName("Face Sense");
  }, []);

  const [isPlaying, setIsPlaying] = useState(false); // To control video play state

  const playerRef = useRef(null);
  const videoContainerRef = useRef(null); // Ref for the video container

  const handleCatalogClick = () => setCatalogForm1(true);

  const handleTrialClick = () => setTrialForm1(true);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleTrialMouseEnter = () => setIsTrialHovered(true);
  const handleTrialMouseLeave = () => setIsTrialHovered(false);

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  // const handleDemoScroll = () => {
  //   const scroll = document.querySelector(".ScrollTo");
  //   scroll.scrollIntoView({ behavior: "smooth" });
  // };

  useEffect(() => {
    // Create IntersectionObserver to monitor the video
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.5) {
            setIsPlaying(true); // Start playing when 50% is visible
          } else {
            setIsPlaying(false); // Stop playing when less than 50% is visible
          }
        });
      },
      {
        threshold: [0.5], // Trigger when 50% of the video is visible
      }
    );

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current);
    }

    // Clean up observer on unmount
    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Face Recognition Attendance management system: Contactless, Automatic
          cloud-Based Employee Time tracking India | Biometric Technology
        </title>
        <meta
          name="description"
          content="Streamline employee time tracking with our contactless, cloud-based face recognition system India. Experience the ease of automatic attendance management through upgrading your biometric technology."
        />
        <meta
          name="keyword"
          content="face recognition, face recognition technology, facial recognition system, Facial Recognition Technology System India, face recognition attendance system India, biometric attendance system India, attendance management system India, automatic attendance tracking India, cloud attendance system India, contactless attendance system India, employee attendance system India, face recognition time tracking India, Employee time tracking India. "
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/face-recognition-technology"
        />
      </Helmet>

      <section className="Productpage_Section">
        <div className="product_heading">
          <h2>Face Recognition Technology</h2>
        </div>

        <div className="product_details">
          <p>
            Face recognition systems have been widely used today for various
            needs ranging from people tracking to criminal database management
            system, street security, and defense applications. Our flagship
            product Facesense is a simple, yet powerful face recognition tool
            for your attendance management system. Using Facesense, you can make
            your existing IP cameras/CCTVs into an attendance management system.
            Yes! That’s right.
          </p>
        </div>
        <Waypoint>
          <div className="video_container" ref={videoContainerRef}>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=rXlSs0ve_xA"
              ref={playerRef}
              controls={true}
              playing={isPlaying} // Controls video playback
              width="100%"
              height="100%"
            />
          </div>
        </Waypoint>

        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Facesense is a subscription model product that works on
              pay-as-you-use platform making it simple and easy for you to use
              this as a plug & play product minus the hassles of maintaining it,
              for which we’re always there to support. While your employees walk
              into your lobby, the cameras will capture the footage and mark
              their attendance and notify them on the Facesense app on their iOS
              or Android phones. The HR will access their Facesense dashboards
              for monitoring employee attendance and generate a plethora of
              reports for monitoring, tracking, and managing attendance better.
            </p>
          </div>
        </div>

        <div className="application_process">
          <ul>
            <li>
              <p>
                Face recognition model to detect and track people based on 128
                vector points in the face for 100% accuracy for attendance
                management
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrated Facesense application for managing employees, shifts,
                office timings, and more
              </p>
            </li>
            <hr />
            <li>
              <p>
                Plethora of attendance reports with an integrated dashboard for
                HR
              </p>
            </li>
            <hr />
            <li>
              <p>
                Convert your existing IP cameras into an attendance tool with
                Facesense application
              </p>
            </li>
            <hr />
            <li>
              <p>
                Pay-as-you-use model with subscription license pattern saving
                with big investment
              </p>
            </li>
            <hr />
            <li>
              <p>
                iOS and Android apps for your employees to keep a track of their
                attendance reports
              </p>
            </li>
            <hr />
            <li>
              <p>
                Remote attendance punch for your field employees such as sales,
                installation engineers, business development and more
              </p>
            </li>
            <hr />
            <li>
              <p>
                Integrate multiple offices into one unified platform using
                Facesense, ensuring your HR has access to the attendance of
                employees from multiple offices
              </p>
            </li>
          </ul>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>Govt Offices</li>
              <li>SMB companies</li>
              <li>Industries</li>
              <li>Criminal Database Management System</li>
            </ul>
            <ul>
              <li>Street Security</li>
              <li>Schools & College</li>
              <li>Hostels</li>
              <li>Restaurants & Hotels</li>
              <li>Secretariats</li>
            </ul>
            <ul>
              <li>Visitor Management System</li>
            </ul>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="Catalog_button_mobile"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Download catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div>

        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Face_recognition_technology_product_page"}
            />
          </>
        )}

        {trailForm1 && <Trialform setTrialForm1={setTrialForm1} />}
      </section>
      <div className="Schedule_Demo">
        <div className="two-buttons">
          <button
            className="Demo_button_2"
            onClick={handleTrialClick}
            onMouseEnter={handleTrialMouseEnter}
            onMouseLeave={handleTrialMouseLeave}
          >
            Book Free Trial
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Download catalogue
            <img src={imageSrc} />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default FaceTech;
