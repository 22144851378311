import React from "react";
import Products from "./Products/Products";
import { Outlet } from "react-router-dom";
import Form from "./Form/Form";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

const ProductsLayout = () => {
  return (
    <>
      <Header />
      <Products />
      <Outlet />
      <Form />
      <Footer />
    </>
  );
};

export default ProductsLayout;
