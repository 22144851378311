import React from "react";
import "../face/Face.css";
import { useState, useEffect, Suspense } from "react";
import people from "../../../../../assets/Retailyticspage/people.svg";
import Footer1 from "../footer1/Footer1";
import Navigate from "../navigation/Navigate";
import { Helmet } from "react-helmet-async";
import "../../../../../Landing Page/Vehicular Access Control/ANPR.css";
import India from "../../../../../assets/India.svg";
import Catalogform from "../../../../Catalog_Form/Catalogform";
import cloudDownload from "../../../../../assets/cloudDownload.svg";
import cloudDownload1 from "../../../../../assets/cloudDownload1.svg";
import { useContact } from "../../../../../context/ContactProvider";

const FaceDetectionTechnology = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);

  const { setProductName } = useContact();

  useEffect(() => {
    setProductName("Retailytics - Face Detection Technology");
  }, []);

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>
          Face Detection Technology Company| Attendance Management System
        </title>
        <meta
          name="description"
          content="Harness the power of our Face Detection Technology for enhanced security and analytics. Our AI-driven solutions provide accurate, real-time facial detection for attendance management system, surveillance, authentication, CCTV camera,s and access control. Explore our innovative face recognition solutions today. "
        />
        <meta
          name="keyword"
          content="face detection, face recognition detection, facial detection system, face detection camera, face detection cctv, Facial detection Technology System India, Face detection CCTV Camera Bangalore"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com//Retailytics/Face-Detection-Technology"
        />
      </Helmet>
      <Navigate />
      <div className="wholediv">
        <div className="contentsdiv">
          <div className="faceimage">
            <img src={people} alt="people" className="face1" loading="lazy" />
          </div>
          <br />
          <br />
          <div className="para">
            <h2>Face recognition</h2>
            <p>
              Facesense will help in store employee attendance with In/Out logs
              within that store. The HR will be notified of daily and periodical
              attendance registers and the logs with an automated email and can
              access these on the dashboards too. With an inbuilt shift module,
              face recognition-based attendance can be managed for each shift
              with ease.
            </p>
            <br />
            <p>
              Facesense also helps in tracking regular or repeated customers to
              the store that can be linked to customer loyalty programs with
              this report. Using a cataloguing feature, this will give
              periodical reports of your regular customers.
            </p>
            <ul>
              <li>
                <h4>Employees - Attendance and logs</h4>
              </li>
              <li>
                <h4>Customers - Customer loyalty program</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          className="Catalog_button_mobile"
          onClick={() => {
            setCatalogForm1(true);
          }}
        >
          Dowload catalogue
          <img src={cloudDownload} alt="" />
        </button>
      </div>

      {catalogForm1 && (
        <>
          <Catalogform
            setCatalogForm1={setCatalogForm1}
            CatalogName={"Retailytics_product_page"}
          />
        </>
      )}

      <div className="Schedule_Demo_retailytics2">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Dowload catalogue
            <img src={imageSrc} />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Footer1 />
    </>
  );
};

export default FaceDetectionTechnology;
