import React from "react";
import { useState, useRef } from "react";
import icon from "../../assets/Success.svg";
import icon7 from "../../assets/Cancelwhite.svg";
import icon6 from "../../assets/Cancel.svg";
import axios from "axios";
import "./Catalogform.css";
import img1 from "../../assets/Img1.svg";
import img2 from "../../assets/Img2.svg";
import img3 from "../../assets/Img3.svg";
import { BaseURL } from "../../BaseURL";
import Countrycodes from "./Countrycodes.json";
import ReactCaptcha from "../../Captcha/Captcha";
console.log(Countrycodes);
const Catalogform = ({ setCatalogForm1, CatalogName }) => {
  const captchaRef = useRef();
  const captchaConfig = {
    type: 1,
    length: 6,
    font: {
      size: "40px",
      family: "Sora",
      color: "#2e2e2e",
    },
    back: {
      stroke: "#2e2e2e",
      solid: "#ffffff ",
    },
    cssClass: "custom-captcha",
    handleBlur: (e) => {
      handleBlur(e);
    },
    handleFocus: (e) => {
      handleFocus(e);
    },
    Inputcaptcha: "",
    Resultcaptcha: "1",
  };
  const [captchaerror, setCaptchaError] = useState(false);
  const [fielderror, setFieldError] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [firstnameError, setFirstNameError] = useState("");
  const [lastnameError, setLastNameError] = useState("");
  const [phonenumberError, setPhoneNumberError] = useState("");

  const [isfirstnameValid, setIsFirstNameValid] = useState(false);
  const [islastnameValid, setIsLastNameValid] = useState(false);
  const [isphonenumberValid, setIsPhoneNumberValid] = useState(false);

  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Country_code: "91",
    Message: "",
    CatalogName,
  });
  const [isLoading, setIsLoading] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  // const handleNumChangeM = (event) => {
  //   const numericValue = event.target.value.replace(/\D/g, "");
  //   if (numericValue.length > 10) {
  //     setPhoneNumberError("Phone number must be exactly 10 digits.");
  //     setIsPhoneNumberValid(true);
  //   } else {
  //     setPhoneNumberError("");
  //     setIsPhoneNumberValid(false);
  //   }
  //   setFormValues({
  //     ...formValues,
  //     Phone_Number: numericValue.slice(0, 10),
  //   });
  // };

  const handleNumChangeM = (event) => {
    let numericValue = event.target.value.replace(/\D/g, "");

    // Remove leading zeroes
    if (numericValue.startsWith("0")) {
      numericValue = numericValue.substring(1);
    }

    // Limit to 10 digits
    numericValue = numericValue.slice(0, 10);

    setFormValues({
      ...formValues,
      Phone_Number: numericValue,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      setIsEmailValid(false);
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      setIsEmailValid(false);
    } else {
      setEmailError("");
      setIsEmailValid(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // setFormValues({ ...formValues, [name]: value });

    if (name === "Email") {
      validateEmail(value);
      setFormValues({ ...formValues, [name]: value });
    }

    if (name === "First_Name") {
      const nameRegex = /^[A-Za-z\s]+$/;

      if (nameRegex.test(value.trim())) {
        setFormValues({ ...formValues, [name]: value });
        setFirstNameError("");
        setIsFirstNameValid(false);
      } else {
        if (formValues?.First_Name.length === 1) {
          console.log(formValues?.First_Name);
          setFormValues({ ...formValues, First_Name: "" });
          setFirstNameError("");
          setIsFirstNameValid(false);
          return;
        }
        setFirstNameError("Please enter correct name");
        setIsFirstNameValid(true);
      }
    }
    if (name === "Last_Name") {
      const nameRegex = /^[A-Za-z\s]+$/;

      if (nameRegex.test(value.trim())) {
        setFormValues({ ...formValues, [name]: value });
        setLastNameError("");
        setIsLastNameValid(false);
      } else {
        if (formValues?.Last_Name.length === 1) {
          console.log(formValues?.Last_Name);
          setFormValues({ ...formValues, Last_Name: "" });
          setFirstNameError("");
          setIsFirstNameValid(false);
          return;
        }
        setLastNameError("Please enter correct name");
        setIsLastNameValid(true);
      }
    }

    if (name === "Message") {
      setFormValues({ ...formValues, [name]: value });
    }

    if (name === "Country_code") {
      // console.log(value);
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const nameRegex = /^[A-Za-z\s]+$/;
    if (formValues.First_Name.trim() === "") {
      setIsLoading(false);
      alert("Please enter your first name.");
      return;
    } else if (!nameRegex.test(formValues.First_Name)) {
      setIsLoading(false);
      alert(
        "First name should only contain letters. Please remove any numbers or special characters."
      );
      return;
    } else if (formValues.First_Name.trim().length < 3) {
      setIsLoading(false);
      alert("Name should be minimum 3 characters");
      return;
    }
    if (formValues.Last_Name) {
      if (!nameRegex.test(formValues.Last_Name)) {
        setIsLoading(false);
        alert(
          "Last name should only contain letters. Please remove any numbers or special characters."
        );
        return;
      }
    }

    if (formValues.Country_code == "") {
      setIsLoading(false);
      alert("Please select the country code.");
      return;
    }

    if (formValues.Phone_Number.length !== 10) {
      setIsLoading(false);
      alert("Please enter a 10 digit phone number.");
      return;
    }
    if (!isEmailValid) {
      setIsLoading(false);
      alert("Please enter a valid email address.");
      return;
    }

    if (formValues.Message == "") {
      alert("Please enter a message");
      return;
    }

    if (
      [
        formValues.First_Name,
        formValues.Email,
        formValues.Phone_Number,
        formValues.Message,
        formValues.Country_code,
      ].some((field) => field?.trim() === "")
    ) {
      captchaRef.current.createCaptcha();

      setIsLoading(false);
      // alert("Please fill out required fields.");
      setFieldError(true);
      return;
    }

    if (captchaConfig.Inputcaptcha === captchaConfig.Resultcaptcha) {
      setIsLoading(true);
      setShowForm(false);
      await axios.post(`${BaseURL}/api/v1/users/CatalogForm`, formValues, {
        header: {
          "content type": "application.json",
        },
      });
      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
        Country_code: "",
      });
      captchaRef?.current?.createCaptcha();
      setIsLoading(false);
      setShowPopup(true);
      setCaptchaError(false);
      setFieldError(false);
    } else {
      captchaRef?.current?.createCaptcha();
      setFieldError(false);
      setCaptchaError(true);
      setIsLoading(false);
    }
  };

  const handleFocus = (e) => {
    const label = e.target.previousElementSibling;
    label.classList.add("Focused");
  };
  const handleBlur = (e) => {
    const label = e.target.previousElementSibling;
    if (!e.target.value) {
      label.classList.remove("Focused");
    }
  };
  return (
    <>
      {showForm && (
        <>
          <div
            className="Overlay2"
            onClick={() => {
              setCatalogForm1(false);
            }}
          />
          <div className="Popup2">
            <div className="catalog_form">
              <div className="catalogform_heading">
                <img
                  src={icon7}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => {
                    setShowPopup(false);
                    setCatalogForm1(false);
                  }}
                />
                <h3>WE WILL GET IN TOUCH</h3>
                <h5>
                  We will share the product catalog to your email entered here.
                </h5>
              </div>
              <div>
                <form className="Catalogue_Form">
                  <div className="Input_Holder">
                    <label className="Label">First Name*</label>
                    <input
                      type="text"
                      name="First_Name"
                      className="Input_Field"
                      placeholder="Please enter your Name"
                      value={formValues.First_Name}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {firstnameError && (
                      <p
                        style={{
                          color: "red",
                          textAlign: "end",
                          fontSize: "10px",
                          background: "#ffffff",
                        }}
                      >
                        {firstnameError}
                      </p>
                    )}
                  </div>
                  <div className="Input_Holder">
                    <label className="Label">Last Name</label>
                    <input
                      type="text"
                      name="Last_Name"
                      className="Input_Field2"
                      value={formValues.Last_Name}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {lastnameError && (
                      <p
                        style={{
                          color: "red",
                          textAlign: "end",
                          fontSize: "10px",
                          background: "#ffffff",
                        }}
                      >
                        {lastnameError}
                      </p>
                    )}
                  </div>

                  <div className="Input_Holder">
                    <label className="Label">Phone Number*</label>
                    <div className="countrycode">
                      <select
                        className="code"
                        onChange={(event) => handleChange(event)}
                        value={formValues.Country_code}
                        name="Country_code"
                      >
                        {Countrycodes.countries
                          ?.sort((a, b) =>
                            a.dial_code.localeCompare(b.dial_code)
                          )
                          .map((c, i) => (
                            <option key={i} value={c?.dial_code}>
                              {c?.dial_code}
                            </option>
                          ))}
                      </select>
                      <input
                        type="text"
                        name="Phone_Number"
                        className="Input_Field3"
                        value={formValues.Phone_Number}
                        placeholder="Please enter a 10-digit Phone Number"
                        onChange={(event) => {
                          handleChange(event);
                          handleNumChangeM(event);
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {phonenumberError && (
                        <p
                          style={{
                            color: "red",
                            textAlign: "end",
                            fontSize: "10px",
                            background: "#ffffff",
                          }}
                        >
                          {phonenumberError}
                        </p>
                      )}
                    </div>
                    <p className="error_text1">
                      Don’t enter mobile number starting with zero.
                    </p>
                  </div>
                  <div className="Input_Holder">
                    <label className="Label">E-Mail*</label>
                    <input
                      type="email"
                      name="Email"
                      className="Input_Field"
                      value={formValues.Email}
                      placeholder="Please enter a valid Email-ID"
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {emailError && (
                      <p
                        style={{
                          color: "red",
                          textAlign: "end",
                          fontSize: "10px",
                          background: "#ffffff",
                        }}
                      >
                        {emailError}
                      </p>
                    )}
                  </div>
                  <div className="Input_Holder">
                    <label className="Label">Your message*</label>
                    <textarea
                      name="Message"
                      className="Message"
                      value={formValues.Message}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="error_box">
                    <ReactCaptcha config={captchaConfig} ref={captchaRef} />
                    {captchaerror && (
                      <p className="error_text1">
                        Captcha verification failed. Please try again.
                      </p>
                    )}
                    {fielderror && (
                      <p className="error_text1">
                        Please fill out required fields.
                      </p>
                    )}
                  </div>

                  <div className="Submit_button">
                    {isLoading ? (
                      <>
                        <div className="Overlay2" />
                        <div className="Popup1">
                          <div className="Logo_Container">
                            <div className="Image">
                              <div className="Img1">
                                <img
                                  className="Spin1"
                                  src={img1}
                                  alt=""
                                  loading="lazy"
                                />
                              </div>
                              <div className="Img2">
                                <img
                                  className="Spin"
                                  src={img2}
                                  alt=""
                                  loading="lazy"
                                />
                              </div>
                              <div className="Img3">
                                <img src={img3} alt="" loading="lazy" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="Form_Submit"
                          onClick={handleSubmit}
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      {showPopup && (
        <>
          <div className="Overlay2" />
          <div className="Popup">
            <img
              src={icon6}
              alt="Close"
              className="CloseButton"
              onClick={() => {
                setShowPopup(false);
                setCatalogForm1(false);
              }}
            />
            <div className="PContents">
              <img src={icon} alt="Popup" />
              <h3>Thank you!</h3>
              <p>We sent you the product catalog to your email.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Catalogform;
