import React from "react";
import "../security/Security.css";
import { useState, useEffect, Suspense } from "react";
import Navigate from "../navigation/Navigate";
import { Helmet } from "react-helmet-async";
import security from "../../../../../assets/Retailyticspage/security.svg";
import Footer from "../footer1/Footer1";
import "../../../../../Landing Page/Vehicular Access Control/ANPR.css";
import India from "../../../../../assets/India.svg";
import Catalogform from "../../../../Catalog_Form/Catalogform";
import cloudDownload from "../../../../../assets/cloudDownload.svg";
import cloudDownload1 from "../../../../../assets/cloudDownload1.svg";
import { useContact } from "../../../../../context/ContactProvider";

const IntrusionDetection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);

  const { setProductName } = useContact();

  useEffect(() => {
    setProductName("Retailytics - Intrusion Detection");
  }, []);

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Navigate />
      <Helmet>
        <title>
          Intrusion Detection System | Advanced Security Sensors Solutions
        </title>
        <meta
          name="description"
          content="Safeguard your property with our Intrusion Detection System. Using cutting-edge sensors and real-time monitoring, our system provides instant alerts for unauthorized access, ensuring optimal best security and peace of mind. Explore our innovative security solutions today."
        />
        <meta
          name="keyword"
          content=" intrusion detection system, intrusion prevention system, intrusion detection, ids system, intruder detection system, best intrusion detection system"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com//Retailytics/Intrusion-detection"
        />
      </Helmet>
      <div className="wholediv3">
        <div className="contentsdiv2">
          <div className="securityimage">
            <img
              src={security}
              alt="security"
              className="face1"
              loading="lazy"
            />
          </div>
          <br />
          <br />
          <div className="para3">
            <h2>Security Analytics</h2>
            <p>
              Autonomous security management is the need of the hour to ensure
              preventive security systems are protecting your store always. Our
              intrusion & breach detection modules are integrated with your
              existing IP cameras, designed to thwart human intrusion or breach
              into your stores as defined by you. This is integrated with Apps
              and hooters at the store to prevent the crime even before it
              happens.
            </p>
            <br />
            <p>
              You can track suspicious people in your store with a simple marker
              on the person in the dashboard, and all the cameras within the
              store are highlighted with this track this person including
              creating a virtual path of this person to notify you about this.
              This will help in tracking blacklisted or suspicious people in
              your store.
            </p>
            <br />
            <p>
              It’s a known factor that security personnel are mostly in sleep
              during non-operating hours of the stores. But if your store
              categorizes into high value goods, monitoring them is important.
              Our face recognition based patrolling help you with this. With the
              help of existing cameras installed outside your store, you can
              define the patrol path with which the security personnel has to
              follow this path within the stipulated time, during which our
              analytics will recognise the face and create an automated
              patrolling report for you, to ensure that this person was alert &
              patrolling.
            </p>
            <ul>
              <li>
                <h4>Intrusion & breach detection with alerts</h4>
              </li>
              <li>
                <h4>Person tracking within the store</h4>
              </li>
              <li>
                <h4>Security patrol tracking system</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          className="Catalog_button_mobile"
          onClick={() => {
            setCatalogForm1(true);
          }}
        >
          Dowload catalogue
          <img src={cloudDownload} alt="" />
        </button>
      </div>

      {catalogForm1 && (
        <>
          <Catalogform
            setCatalogForm1={setCatalogForm1}
            CatalogName={"Retailytics_product_page"}
          />
        </>
      )}

      <div className="Schedule_Demo_retailytics5">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Dowload catalogue
            <img src={imageSrc} alt="" />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Footer />
    </>
  );
};

export default IntrusionDetection;
