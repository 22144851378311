import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import Header from "../Header/Header";
import "./About.css";
import righttext from "../assets/about/righttext.svg";
import photo1 from "../assets/about/photo1.png";
import photo2 from "../assets/about/photo2.png";
import photo3 from "../assets/about/photo3.png";
import photo4 from "../assets/about/photo4.png";
import photo5 from "../assets/about/photo5.png";
import photo6 from "../assets/about/photo6.png";
import photo7 from "../assets/about/photo7.png";
import photo8 from "../assets/about/photo8.png";
import photo9 from "../assets/about/photo9.png";
import photo10 from "../assets/about/photo10.png";
import photo11 from "../assets/about/photo11.png";
import photo12 from "../assets/about/photo12.png";
import photo13 from "../assets/about/photo13.png";
import photo14 from "../assets/about/photo14.png";
import photo15 from "../assets/about/photo15.png";
import rightarrow from "../assets/about/rightarrow.svg";
import Form from "../Form/Form";
import Footer from "../Footer/Footer";
import "../Products/Products.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useContact } from "../context/ContactProvider";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const { setProductName } = useContact();
  useEffect(() => {
    setProductName("About Page");
  }, []);

  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  const staticContainerRef = useRef(null);
  // const [enlargedPhoto, setEnlargedPhoto] = useState(null);
  const [enlargedPhotoClass, setEnlargedPhotoClass] = useState("");

  // const handlePhotoClick = (src, name, role, content, photoClass) => {
  //   setEnlargedPhoto({ src, name, role, content });
  //   setEnlargedPhotoClass(photoClass);
  // };

  // const handleCloseOverlay = () => {
  //   setEnlargedPhoto(null);
  //   setEnlargedPhotoClass('');
  // };

  return (
    <>
      <Helmet>
        <title>About Us | Learn Our Story & Mission</title>
        <meta
          name="description"
          content="Discover who we are and our mission to provide innovative solutions. Learn about our values, team, and commitment to excellence. Explore our journey today!"
        />
        <meta name="keyword" content="About us, Okulr" />
        <link rel="canonical" href="https://www.okulr.com/about" />
      </Helmet>
      <Header />
      <section className="about_Section">
        <div className="about_heading">
          <h2>We're the OKULRs</h2>
        </div>
        <div className="about_details">
          <p>
            We’re a bunch of Tech Minds, young, energetic, always curious &
            disruptive to fix a way around for complex problems through tech.
            We’re wired to think tech as the only universal solution to solve
            problems we see around us every day, to make the society we live in
            better. We’ve been researching, learning, implementing products and
            projects in computer vision, data science, robotics and IoT. Fun,
            frolic, work, solve, keep walking, don’t stop, if we don’t do it -
            someone else will do it, let’s do this…. are few of the words that
            describe us.
          </p>
        </div>
        <div className="aboutContainer">
          <div className="left-container">
            <div className="PhotoContainer">
              <div className="row-1">
                <div
                  className={`photo photo-1 ${
                    enlargedPhotoClass === "photo-1" ? "enlarged" : ""
                  }`}
                >
                  <img
                    src={photo1}
                    alt="karthik"
                    // onClick={() => handlePhotoClick(photo1, 'Karthik', 'Co-founder', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/karthik-t-65503548"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>karthik</h2>
                      <p>Co-founder</p>
                    </div>
                  </div>
                </div>
                <div className="photo-2">
                  <img
                    src={photo2}
                    alt="eeshan"
                    // onClick={() => handlePhotoClick(photo2, 'Eeshan', 'Co-founder', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/eeshan-rao-8b9a261aa"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Eeshan</h2>
                      <p>Co-founder</p>
                    </div>
                  </div>
                </div>
                <div className="photo-3">
                  <img
                    src={photo3}
                    alt="Neeraj"
                    // onClick={() => handlePhotoClick(photo3, 'Neeraj', 'Chief Technology Officer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      <a href="">
                        <img src={rightarrow} alt="right arrow" />
                      </a>
                    </div>
                    <div className="hoverText">
                      <h2>Neeraj</h2>
                      <p>Chief Technology Officer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-2">
                <div className="photo-4">
                  <img
                    src={photo4}
                    alt="Nambirajan"
                    // onClick={() => handlePhotoClick(photo4, 'Nambirajan', 'Principal Consultant', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      <a href="">
                        <img src={rightarrow} alt="right arrow" />
                      </a>
                    </div>
                    <div className="hoverText">
                      <h2>Nambirajan</h2>
                      <p>Principal Consultant</p>
                    </div>
                  </div>
                </div>
                <div className="photo-5">
                  <img
                    src={photo5}
                    alt="Deblina"
                    // onClick={() => handlePhotoClick(photo5, 'Deblina', 'Education Business Head', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/deblina-sarkhel-ba8824b9"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Deblina</h2>
                      <p>Education Business Head</p>
                    </div>
                  </div>
                </div>
                <div className="photo-6">
                  <img
                    src={photo6}
                    alt="Rakshita"
                    //  onClick={() => handlePhotoClick(photo6, 'Rakshita', 'Sr. Digital Marketing Analyst', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/rakshita-n-c-a691aa59"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Rakshita</h2>
                      <p>Sr. Digital Marketing Analyst</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-3">
                <div className="photo-7">
                  <img
                    src={photo7}
                    alt="Sridhar"
                    // onClick={() => handlePhotoClick(photo7, 'Sridhar', 'Sr. UI/UX Designer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/sridhar-r-b9883914b"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Sridhar</h2>
                      <p>Sr. UI/UX Designer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-8">
                  <img
                    src={photo8}
                    alt="Arjun"
                    // onClick={() => handlePhotoClick(photo8, 'Arjun', 'Computer Vision Developer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/arjun-mani01"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Arjun</h2>
                      <p>Computer Vision Developer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-9">
                  <img
                    src={photo9}
                    alt="Manjunath"
                    // onClick={() => handlePhotoClick(photo9, 'Manjunath', 'Computer Vision Developer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/manjunath-s-1149a6235"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Manjunath</h2>
                      <p>Computer Vision Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-4">
                <div className="photo-10">
                  <img
                    src={photo10}
                    alt="Arjun"
                    // onClick={() => handlePhotoClick(photo10, 'Arjun', 'Cloud Engineering Associate', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/arjun-dharman-b16353270"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Arjun</h2>
                      <p>Cloud Engineering Associate</p>
                    </div>
                  </div>
                </div>
                <div className="photo-11">
                  <img
                    src={photo11}
                    alt="Santhosh"
                    // onClick={() => handlePhotoClick(photo11, 'Santhosh', 'iOS Developer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/santhosh-r-ab70bb241?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Santhosh</h2>
                      <p>iOS Developer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-12">
                  <img
                    src={photo12}
                    alt="Poonam"
                    // onClick={() => handlePhotoClick(photo12, 'Poonam', 'Android Developer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/poonam-deshmukh-912444232"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Poonam</h2>
                      <p>Android Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-5">
                <div className="photo-13">
                  <img
                    src={photo13}
                    alt="Sahana"
                    // onClick={() => handlePhotoClick(photo13, 'Sahana', 'Finance Associate', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/sahana-k-87458b195"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Sahana</h2>
                      <p>Finance Associate</p>
                    </div>
                  </div>
                </div>
                <div className="photo-14">
                  <img
                    src={photo14}
                    alt="Venugopal"
                    // onClick={() => handlePhotoClick(photo14, 'Venugopal', 'Customer Support Lead', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/venugopal-s-16960a168"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Venugopal</h2>
                      <p>Customer Support Lead</p>
                    </div>
                  </div>
                </div>
                <div className="photo-15">
                  <img
                    src={photo15}
                    alt="Megha"
                    // onClick={() => handlePhotoClick(photo15, 'Megha', 'Full-stack Developer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/megha-s-holur"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Megha</h2>
                      <p>Full-stack Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {enlargedPhoto && (
              <div className='overlay' onClick={handleCloseOverlay}>
                <div className='enlargedContainer'>
                  <div className='enlargedPhotoContainer'>
                    <img src={enlargedPhoto.src} alt={enlargedPhoto.alt} />
                  </div>
                  <div className='enlargedContent'>
                    <h2>{enlargedPhoto.name}</h2>
                    <p>{enlargedPhoto.role}</p>
                    <p>{enlargedPhoto.content}</p>
                  </div>
                </div>
              </div>
            )} */}
          </div>
          <div className="staticContainer" ref={staticContainerRef}>
            <img src={righttext} alt="text" className="staticImage" />
          </div>
        </div>
        <div className="aboutContainer-mobile">
          <div className="left-container-mobile">
            <div className="PhotoContainer">
              <div className="row-1-m">
                <div className="photo-1">
                  <img
                    src={photo1}
                    alt="karthik"
                    // onClick={() => handlePhotoClick(photo1, 'Karthik', 'Co-founder', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/karthik-t-65503548"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Karthik</h2>
                      <p>Co-founder</p>
                    </div>
                  </div>
                </div>
                <div className="photo-2">
                  <img
                    src={photo2}
                    alt="eeshan"
                    // onClick={() => handlePhotoClick(photo2, 'Eeshan', 'Co-founder', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/eeshan-rao-8b9a261aa"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Eeshan</h2>
                      <p>Co-founder</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-2-m">
                <div className="photo-3">
                  <img
                    src={photo3}
                    alt="Neeraj"
                    // onClick={() => handlePhotoClick(photo3, 'Neeraj', 'Chief Technology Officer', 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      <a href=" ">
                        <img src={rightarrow} alt="right arrow" />
                      </a>
                    </div>
                    <div className="hoverText">
                      <h2>Neeraj</h2>
                      <p>Chief Technology Officer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-4">
                  <img
                    src={photo4}
                    alt="Nambirajan"
                    // onClick={() => handlePhotoClick(photo4, 'Nambirajan', 'Principal Consultant')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      <img src={rightarrow} />
                    </div>
                    <div className="hoverText">
                      <h2>Nambirajan</h2>
                      <p>Principal Consultant</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-3-m">
                <div className="photo-5">
                  <img
                    src={photo5}
                    alt="Deblina"
                    // onClick={() => handlePhotoClick(photo5, 'Deblina', 'Education Business Head')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/deblina-sarkhel-ba8824b9"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Deblina</h2>
                      <p>Education Business Head</p>
                    </div>
                  </div>
                </div>
                <div className="photo-6">
                  <img
                    src={photo6}
                    alt="Rakshita"
                    // onClick={() => handlePhotoClick(photo6, 'Rakshita', 'Sr. Digital Marketing Analyst')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/rakshita-n-c-a691aa59"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Rakshita</h2>
                      <p>Sr. Digital Marketing Analyst</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-4-m">
                <div className="photo-7">
                  <img
                    src={photo7}
                    alt="Sridhar"
                    // onClick={() => handlePhotoClick(photo7, 'Sridhar', 'Sr. UI/UX Designer')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/sridhar-r-b9883914b"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Sridhar</h2>
                      <p>Sr. UI/UX Designer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-8">
                  <img
                    src={photo8}
                    alt="Arjun"
                    // onClick={() => handlePhotoClick(photo8, 'Arjun', 'Computer Vision Developer')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/arjun-mani01/"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Arjun</h2>
                      <p>Computer Vision Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-5-m">
                <div className="photo-9">
                  <img
                    src={photo9}
                    alt="Manjunath"
                    // onClick={() => handlePhotoClick(photo9, 'Manjunath', 'Computer Vision Developer')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/manjunath-s-1149a6235"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Manjunath</h2>
                      <p>Computer Vision Developer</p>
                    </div>
                  </div>
                </div>

                <div className="photo-10">
                  <img
                    src={photo10}
                    alt="Arjun"
                    // onClick={() => handlePhotoClick(photo10, 'Arjun', 'Cloud Engineering Associate')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/arjun-dharman-b16353270"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Arjun</h2>
                      <p>Cloud Engineering Associate</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-6-m">
                <div className="photo-11">
                  <img
                    src={photo11}
                    alt="Santhosh"
                    // onClick={() => handlePhotoClick(photo11, 'Santhosh', 'iOS Developer')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/santhosh-r-ab70bb241"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Santhosh</h2>
                      <p>iOS Developer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-12">
                  <img
                    src={photo12}
                    alt="Poonam"
                    // onClick={() => handlePhotoClick(photo12, 'Poonam', 'Android Developer')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/poonam-deshmukh-912444232"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Poonam</h2>
                      <p>Android Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-7-m">
                <div className="photo-13">
                  <img
                    src={photo13}
                    alt="Sahana"
                    // onClick={() => handlePhotoClick(photo13, 'Sahana', 'Finance Associate')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/sahana-k-87458b195"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Sahana</h2>
                      <p>Finance Associate</p>
                    </div>
                  </div>
                </div>
                <div className="photo-14">
                  <img
                    src={photo14}
                    alt="Venugopal"
                    // onClick={() => handlePhotoClick(photo14, 'Venugopal', 'Customer Support Lead')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/venugopal-s-16960a168"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Venugopal</h2>
                      <p>Customer Support Lead</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-8-m">
                <div className="photo-15">
                  <img
                    src={photo15}
                    alt="Megha"
                    // onClick={() => handlePhotoClick(photo15, 'Megha', 'Full-stack Developer')}
                  />
                  <div className="gradient-overlay">
                    <div className="rightpic">
                      {/* <a href="https://www.linkedin.com/in/megha-s-holur"> */}
                      <img src={rightarrow} alt="right arrow" />
                      {/* </a> */}
                    </div>
                    <div className="hoverText">
                      <h2>Megha</h2>
                      <p>Full-stack Developer</p>
                    </div>
                  </div>
                </div>
                <div className="photo-14-m">
                  <img
                    src={photo14}
                    alt="Venugopal"
                    // onClick={() => handlePhotoClick(photo14, 'Venugopal', 'Customer Support Lead')}
                  />
                  <div className="gradient-overlay">
                    {/* <div className='rightpic'>
                      <a href="https://www.linkedin.com/in/venugopal-s-16960a168"><img src={rightarrow} alt='right arrow' /></a>
                    </div> */}
                    {/* <div className='hoverText'>
                      <h2>Venugopal</h2>
                      <p>Customer Support Lead</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* {enlargedPhoto && (
              <div className='overlay' onClick={handleCloseOverlay}>
                <div className='enlargedContainer'>
                  <div className='enlargedPhotoContainer'>
                    <img src={enlargedPhoto.src} alt={enlargedPhoto.alt} />
                  </div>
                  <div className='enlargedContent'>
                    <h2>{enlargedPhoto.name}</h2>
                    <p>{enlargedPhoto.role}</p>
                    <p>{enlargedPhoto.content}</p>
                  </div>
                </div>
              </div>
            )} */}
          </div>
          <div className="staticContainer-mobile" ref={staticContainerRef}>
            <img src={righttext} alt="text" className="staticImage" />
          </div>
        </div>
      </section>
      <div className="about_subsection">
        <h2>Want to be a part of Okulr?</h2>
        <button className="about_button">
          <a href="/careers">Join Us</a>
        </button>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default About;
