import React, { useState } from "react";
import "../navigation/Navigate.css";
import { useNavigate } from "react-router-dom";

const Navigate = () => {
  const [activePath, setActivePath] = useState(window.location.pathname);
  const navigate = useNavigate();

  function handleNavigation(path) {
    setActivePath(path);
    navigate(path);
  }

  return (
    <div className="Main_Points">
      <div
        className={`Point ${
          activePath === "/Retailytics/Face-Detection-Technology"
            ? "active"
            : ""
        }`}
        onClick={() =>
          handleNavigation("/Retailytics/Face-Detection-Technology")
        }
      >
        <span className="Tooltip">Face Recognition</span>
      </div>
      <div
        className={`Point ${
          activePath === "/Retailytics/people-counting" ? "active" : ""
        }`}
        onClick={() => handleNavigation("/Retailytics/people-counting")}
      >
        <span className="Tooltip">Footfall Analytics</span>
      </div>
      <div
        className={`Point ${
          activePath === "/Retailytics/Crowd-detection" ? "active" : ""
        }`}
        onClick={() => handleNavigation("/Retailytics/Crowd-detection")}
      >
        <span className="Tooltip">Billing Counter Analytics</span>
      </div>
      <div
        className={`Point ${
          activePath === "/Retailytics/Intrusion-detection" ? "active" : ""
        }`}
        onClick={() => handleNavigation("/Retailytics/Intrusion-detection")}
      >
        <span className="Tooltip">Security Analytics</span>
      </div>
      <div
        className={`Point ${
          activePath === "/Retailytics/Retail-Digital-Transformation"
            ? "active"
            : ""
        }`}
        onClick={() =>
          handleNavigation("/Retailytics/Retail-Digital-Transformation")
        }
      >
        <span className="Tooltip">Digital Transformation</span>
      </div>
    </div>
  );
};

export default Navigate;
