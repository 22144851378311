import React, { useState, useEffect } from "react";
import "./Page.css";
import "./SmartPole.css";
import { Helmet } from "react-helmet-async";
import image from "../../assets/smartpole.png";
import icon from "../../assets/Point.svg";
import India from "../../assets/India.svg";
import Catalogform from "../Catalog_Form/Catalogform";
import cloudDownload from "../../assets/cloudDownload.svg";
import cloudDownload1 from "../../assets/cloudDownload1.svg";
import { useContact } from "../../context/ContactProvider";

const SmartPole = () => {
  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);

  const { setProductName } = useContact();
  useEffect(() => {
    setProductName("Smart Poles");
  }, []);

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>
          Smart Poles Manufactures for Smart City | Smart Pole Design Technology
          System
        </title>
        <meta
          name="description"
          content=" As a leading smart poles manufacturer, we specialize in crafting cutting-edge solutions systems for urban infrastructure. Our smart poles integrate advanced technologies to enhance safety, connectivity, and sustainability in smart cities worldwide. Explore our range of innovative products designed to transform urban spaces."
        />
        <meta
          name="keyword"
          content=" Smart Poles India, Best smart pole, Smart pole manufacture in India, smart pole manufacture, smart pole for smart city, Smart Pole Technology, Smart LED street lights, Emergency response system, safe city, smart cities in India, pollution monitoring, environmental sensors, Smart CCTV, Video surveillance for streets, city surveillance"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/smart-pole-solution"
        />
      </Helmet>
      <section className="Productpage_Section">
        <div className="product_heading">
          <h2>Ecopole - One pole, many Possibilities!</h2>
        </div>
        <div className="product_details">
          <p>
            Ecopole is one of innovative and disruptive product in the market
            catering to smart cities, municipalities, urban landscapes, large
            residential complex, industrial parks and more. One product
            encompasses multiple devices integrating as one unified solution
            giving data to multiple agencies in the city. It is a perfect
            example of incredible design meeting the required functionality.
            Equipped with multiple intelligent devices such as AI cameras, smart
            lighting, weather stations, public WiFi nodes, emergency response
            system, solar power charging, electric vehicle charger and much
            more.{" "}
          </p>
        </div>
        <div className="Pole_Section">
          <div className="Image_Points">
            <div className="SPoint1">
              <span className="Spointer_text1">
                Integrated Solar Grid-Connect System
              </span>
              <img src={icon} alt="Point" className="pointer_img" />
            </div>
            <div className="SPoint2">
              <span className="Spointer_text2"> Cleverlite IoT Lights </span>
              <img src={icon} alt="Point" />
            </div>
            <div className="SPoint3">
              <img src={icon} alt="Point" />
              <span className="Spointer_text3">WiFi Hotspot</span>
            </div>
            <div className="SPoint4">
              <img src={icon} alt="Point" />
              <span className="Spointer_text4">Speaker</span>
            </div>
            <div className="SPoint5">
              <img src={icon} alt="Point" />
              <span className="Spointer_text5">LED Display</span>
            </div>
            <div className="SPoint6">
              <img src={icon} alt="Point" />
              <span className="Spointer_text6">Weather Station</span>
            </div>
            <div className="SPoint7">
              <img src={icon} alt="Point" />
              <span className="Spointer_text7">AI Cameras</span>
            </div>
            <div className="SPoint8">
              <img src={icon} alt="Point" />
              <span className="Spointer_text8">Emergency Response System</span>
            </div>
            <div className="SPoint9">
              <img src={icon} alt="Point" />
              <span className="Spointer_text9">Electric Vehicle Charger</span>
            </div>
            <img src={image} alt="SmartPoleimg" />
          </div>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li> Smart Cities </li>
              <li> Safe Cities </li>
              <li> City streets </li>
              <li>Highways </li>
              <li> Ring roads </li>
              <li> Parks </li>
              <li>Gated Residential Communities </li>
            </ul>
          </div>
        </div>
      </section>
      <div>
        <button
          type="button"
          className="Catalog_button_mobile"
          onClick={() => {
            setCatalogForm1(true);
          }}
        >
          Dowload catalogue
          <img src={cloudDownload} alt="" />
        </button>
      </div>

      {catalogForm1 && (
        <>
          <Catalogform
            setCatalogForm1={setCatalogForm1}
            CatalogName={"Smart_poles_product_page"}
          />
        </>
      )}

      <div className="Schedule_Demo">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Download catalogue
            <img src={imageSrc} />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default SmartPole;
