import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import icon4 from "../assets/Reload.svg";
import volume from "../assets/volume.svg";

const ReactCaptcha = forwardRef(({ config }, ref) => {
  const [captchInput, setCaptchInput] = useState("");
  const [code, setCode] = useState("");
  const [resultCode, setResultCode] = useState("");

  useImperativeHandle(ref, () => ({
    createCaptcha: createCaptcha,
  }));
  useEffect(() => {
    config.Inputcaptcha = captchInput;
    config.Resultcaptcha = resultCode;
  }, [captchInput, resultCode]);

  useEffect(() => {
    const captcahCanvas = document.getElementById("captcahCanvas");
    const ctx = captcahCanvas.getContext("2d");
    ctx.fillStyle = config.back.solid;
    ctx.fillRect(0, 0, captcahCanvas.width, captcahCanvas.height);

    ctx.beginPath();

    captcahCanvas.style.letterSpacing = "10px";
    ctx.font = `${config.font.size} ${config.font.family}`;
    ctx.fillStyle = config.font.color;
    ctx.textBaseline = "middle";
    ctx.fillText(code, 65, 25);
    if (config.back.stroke) {
      ctx.strokeStyle = config.back.stroke;
      for (let i = 0; i < 150; i++) {
        ctx.moveTo(Math.random() * 400, Math.random() * 400);
        ctx.lineTo(Math.random() * 400, Math.random() * 400);
      }
      ctx.stroke();
    }
    setCaptchInput("");
  }, [code]);

  useEffect(() => {
    if (config) {
      if (!config.font || !config.font.size) {
        config.font.size = "40px";
      }
      if (!config.font || !config.font.family) {
        config.font.family = "Arial";
      }
      if (!config.strokeColor) {
        config.strokeColor = "#f20c6c";
      }
      if (!config.length) {
        config.length = 6;
      }
      if (!config.cssClass) {
        config.cssClass = "";
      }

      if (!config.type) {
        config.type = 1;
      }

      if (!config.back || !config.back.stroke) {
        config.back.stroke = "";
      }
      if (!config.back || !config.back.solid) {
        config.back.solid = "#f2efd2";
      }

      createCaptcha();
    }
  }, []);

  const createCaptcha = () => {
    switch (config.type) {
      case 1:
        let char =
          Math.random().toString(24).substring(2, config.length) +
          Math.random().toString(24).substring(2, 4);
        setCode(char.toUpperCase());
        setResultCode(char.toUpperCase());
        break;
      case 2:
        let num1 = Math.floor(Math.random() * 99);
        let num2 = Math.floor(Math.random() * 9);
        let operators = ["+", "-"];
        let operator = operators[Math.floor(Math.random() * operators.length)];
        setCode(`${num1}${operator}${num2}=?`);
        setResultCode(operator === "+" ? num1 + num2 : num1 - num2);
        break;
      default:
        break;
    }
  };

  const playCaptcha = () => {
    const msg = new SpeechSynthesisUtterance(code.split("").join(" "));
    msg.pitch = 0.1;
    window.speechSynthesis.speak(msg);
  };

  const checkCaptcha = () => {
    if (captchInput !== resultCode) {
      console.log(code);
      console.log(captchInput);
      console.log(resultCode);
      console.log("Invalid");
    } else {
      console.log(captchInput);
      console.log(resultCode);
      console.log("Valid");
    }
  };

  return (
    <div className="Input_Holder">
      <label className="Label"> Captcha*</label>
      <input
        type="text"
        className="Input_Field"
        value={captchInput}
        placeholder="Enter Captcha here"
        onChange={(e) => setCaptchInput(e.target.value)}
        onFocus={(e) => config.handleFocus(e)}
        onBlur={(e) => config.handleBlur(e)}
      />
      <div className="Captcha_Re">
        <canvas
          className="Captcha_C"
          id="captcahCanvas"
          width="400"
          height="40"
        ></canvas>
        <p
          className="cpt-btn"
          onClick={playCaptcha}
          style={{ cursor: "pointer" }}
        >
          <img src={volume} alt="" />
        </p>
        <img className="Re" src={icon4} alt="Reload" onClick={createCaptcha} />
      </div>
    </div>
  );
});

export default ReactCaptcha;
