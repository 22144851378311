import React, { useState, useEffect, useRef } from "react";
import "./Products.css";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import chevronRight from "../assets/chevronRight.svg";
import chevronLeft from "../assets/chevronLeft.svg";

// Tabs configuration
const tabs = [
  {
    name: "Facesense",
    customText: "Face Recognition Technology",
    link: "/face-recognition-technology",
  },
  {
    name: "Retailytics",
    customText: "Retail Analytics Solution",
    link: "/Retail-Analytics-Solution", // General Retailytics tab
  },
  {
    name: "Traffic Enforcement",
    customText: "Automated Traffic Enforcement System",
    link: "/automated-traffic-enforcement-system",
  },
  {
    name: "EZ Parking",
    customText: "Smart, Paperless & Easy Parking solution",
    link: "/Ezparking",
  },
  {
    name: "Smart Pole",
    customText: "Ecopole - One pole, many Possibilities!",
    link: "/smart-pole-solution",
  },
  {
    name: "Mahila Suraksha",
    customText: "Mahila Suraksha Safety Island",
    link: "/smart-poles",
  },
  {
    name: "Seema Suraksha",
    customText: "Perimeter Smart Security System",
    link: "/perimeter-smart-security-system",
  },
  // {
  //   name: "Vehicle Telematics",
  //   customText: "Vehicle Telematics",
  //   link: "/vehicle-telematics",
  // },
  {
    name: "Suraksha Netra",
    customText: "Intrusion Detection System",
    link: "/suraksha-netra",
  },
  // {
  //   name: "Patrol Surveillance",
  //   customText: "Patrol vehicle onboard surveillance",
  //   link: "/patrol-vehicle-surveillance",
  // },
  // {
  //   name: "Signal Free Corridor",
  //   customText: "Ambulance Signal Free Corridor",
  //   link: "/ambulance-signal-free-corridor",
  // },
];

const Products = () => {
  const [selected, setSelected] = useState(null);
  const [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop === 0) {
        setScrollDirection("up");
        hidebtn();
      } else if (currentScrollTop === 2641) {
        setScrollDirection("down");
      } else if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
        hidebtn();
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Logic to determine the active tab based on URL
  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname;

      // Check if the current path contains "Retailytics" to activate the Retailytics tab
      const isRetailyticsActive = currentPath.includes("/Retailytics");

      const tab = isRetailyticsActive
        ? tabs.find((tab) => tab.name === "Retailytics") // Set Retailytics as active if path contains "Retailytics"
        : tabs.find((tab) => tab.link === currentPath); // Otherwise, find the tab that matches the exact path

      setSelected(tab || tabs[0]);
    };

    window.addEventListener("popstate", handlePopState);
    handlePopState();
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleClick = (tab) => {
    setSelected(tab);
    window.scrollTo(0, 0);
  };

  const selectedChipRef = useRef(null);

  useEffect(() => {
    if (selectedChipRef.current) {
      selectedChipRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selected]);

  const tabsClassName = `tabs ${scrollDirection === "down" ? "hidden" : ""}`;
  const tabsRef = useRef(null);
  const scrollTabs = (direction) => {
    const tabWidth = tabsRef.current.children[0].offsetWidth;
    const container = tabsRef.current;
    hidebtn();
    if (container) {
      if (direction === "left") {
        container.scrollLeft -= tabWidth;
      } else {
        container.scrollLeft += tabWidth;
      }
    }
  };

  useEffect(() => {
    hidebtn();
    const container = tabsRef.current;
    if (container) {
      container.addEventListener("scroll", hidebtn);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", hidebtn);
      }
    };
  }, [tabsRef]);

  const hidebtn = () => {
    const container = tabsRef.current;
    const isAtStart = container.scrollLeft === 0;
    const isAtEnd =
      container.scrollLeft + container.offsetWidth >= container.scrollWidth;
    const scrollLeftButton = document.querySelector(".scroll-btn-left");
    const scrollRightButton = document.querySelector(".scroll-btn-right");
    if (scrollLeftButton) {
      scrollLeftButton.classList.toggle("hidden", isAtStart);
    }

    if (scrollRightButton) {
      scrollRightButton.classList.toggle("hidden", isAtEnd);
    }
  };

  return (
    <>
      <section className={`Product_components`}>
        <button
          className={`scroll-btn-left ${
            scrollDirection === "down" ? "hidden" : ""
          }`}
          type="button"
          onClick={() => {
            scrollTabs("left");
            hidebtn();
          }}
        >
          <img src={chevronLeft} alt="" />
        </button>
        <div className={tabsClassName} ref={tabsRef}>
          {tabs.map((tab) => (
            <div key={tab.name} className="tooltip">
              <NavLink
                to={tab.link}
                onClick={() => {
                  handleClick(tab);
                  hidebtn();
                }}
              >
                <button
                  ref={selected === tab ? selectedChipRef : null}
                  className={`chip ${
                    selected === tab ? "chip-selected" : "chip-unselected"
                  }`}
                  type="button"
                >
                  <span className="chip-text">{tab.name}</span>
                  {selected === tab && (
                    <motion.span
                      layoutId="pill-tab"
                      transition={{ type: "spring", duration: 0.5 }}
                      className={`chip-background`}
                    />
                  )}
                </button>
                <span className="tooltiptext">{tab.customText}</span>
              </NavLink>
            </div>
          ))}
        </div>
        <button
          className={`scroll-btn-right ${
            scrollDirection === "down" ? "hidden" : ""
          }`}
          type="button"
          onClick={() => {
            scrollTabs("right");
            hidebtn();
          }}
        >
          <img src={chevronRight} alt="" />
        </button>
      </section>
    </>
  );
};

export default Products;
