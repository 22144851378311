import React, { useState } from "react";
import "./Ezparking.css";
import { Helmet } from "react-helmet-async";
import Catalogform from "../../Products/Catalog_Form/Catalogform";
import India from "../../assets/India.svg";
import cloudDownload from "../../assets/cloudDownload.svg";
import cloudDownload1 from "../../assets/cloudDownload1.svg";
import ezparking_banner from "../../assets/ezparking_banner.jpg";
import Footer from "../../Footer/Footer";
import Form from "../../Form/Form";
import Header from "../../Header/Header";
import Ezparking from "../../assets/Ezparking.svg";
import EZdiagram from "../../assets/ezdiagram.jpg";

const EzparkingHubliDharwad = () => {
  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;
  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Helmet>
        <title>
          Smart Paperless Parking Management Technology System Hubli-Dharwad |
          Ezparking
        </title>
        <meta
          name="description"
          content="Our Parking Management System optimizes parking operations with advanced technology Hubli. From space availability monitoring to automated payment systems, our solution enhances efficiency, reduces congestion, and improves user experience. Explore our comprehensive paperless parking solutions for smarter cities today Dharwad."
        />
        <meta
          name="keyword"
          content=" Automated parking system Hubli, Parking system in Hubli, Automated parking in Hubli, Multi-level parking in Hubli, Paperless parking management in Hubli, Automated parking companies in Hubli, Parking management service in Hubli, parking management Hubli, car parking management Hubli, parking space management Hubli, parking service management Hubli, parking control management Hubli, Automated parking system Dharwad, Parking system in Dharwad, Automated parking in Dharwad, Multi-level parking in Dharwad, Paperless parking management in Dharwad, Automated parking companies in Dharwad, Parking management service in Dharwad, parking management Dharwad, car parking management Dharwad, parking space management Dharwad, parking service management Dharwad, parking control management Dharwad."
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/Ezparking-Hubli-Dharwad"
        />
      </Helmet>
      <Header />
      <section className="Productpage_Section">
        <div className="product_heading_ez">
          {/* <h2>EZ Parking for Hubli and Dharwad</h2> */}
          <img src={Ezparking} alt="logo" />
        </div>
        <div className="product_details">
          <h1>Smart, Paperless & Easy Parking solution</h1>
          <p>
            <strong> Hey Hubli and Dharwad!&nbsp;</strong>EZParking from Okulr
            uses artificial intelligence to automate your parking space with
            ease. As the name suggests, parking must be easy and not
            complicated. When banking, bill payments, education, transport,
            shopping and many more sectors have gone paperless, why should
            parking management be on the age-old technology of paper-based
            parking ticket?{" "}
          </p>
        </div>
        <div className="video_container">
          <img src={ezparking_banner} alt="" loading="lazy" />
        </div>
        <div className="About_product">
          <h3>About the Product</h3>
          <div>
            <p>
              Explore our EZ Parking management system to manage your parking
              space better and efficiently without the hassles of managing it.
              It works on our Opticsense neural compute platform synchronizing
              with ANPR camera, edge video analytics, mobile apps to make
              parking a breeze. Combining it with our AI excellence award
              winning managed services, we design, build and operate your
              parking space with our EZParking product, letting you focus on
              core works you specialise in. Effortless, easy and paperless
              parking management services from Okulr is a state-of-the-art AI
              based automation solution to make your parking space effective,
              productive & decisive.
            </p>
          </div>
        </div>
        <div className="application_process">
          <ul>
            <li>
              <p>Artificial intelligence enabled parking management system</p>
            </li>
            <hr />
            <li>
              <p>Powered with ANPR edge analytics cameras</p>
            </li>
            <hr />
            <li>
              <p>Integration with boom barriers and automated bollards</p>
            </li>
            <hr />
            <li>
              <p>
                Automated number plate recognition, Vehicle type detection,
                Vehicle counting
              </p>
            </li>
            <hr />
            <li>
              <p>ANPR integrated APP based parking system</p>
            </li>
            <hr />
            <li>
              <p>App, QR, AR based payment gateway for easier payments</p>
            </li>
            <hr />
            <li>
              <p>Optional integration with drive face recognition capture</p>
            </li>
            <hr />
            <li>
              <p>Parking bay availability notification on premises and apps</p>
            </li>
            <hr />
            <li>
              <p>
                Centralised dashboard with data analytics for holistic view of
                parking management
              </p>
            </li>
            <hr />
            <li>
              <p>
                Wealth of EIS & MIS reports for as an affirmative decision
                support system
              </p>
            </li>
            <hr />
          </ul>
        </div>
        <div className="diagram">
          <img src={EZdiagram} alt="ezdiagram" />
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>City Wide Parking</li>
              <li>Integrated Parking Complex</li>
              <li>Shopping Malls</li>
              <li>Govt. Secretariat</li>
              <li>Airports</li>
            </ul>
          </div>
        </div>
      </section>
      <div>
        <button
          type="button"
          className="Catalog_button_mobile"
          onClick={() => {
            setCatalogForm1(true);
          }}
        >
          Dowload catalogue
          <img src={cloudDownload} alt="" />
        </button>
      </div>

      {catalogForm1 && (
        <>
          <Catalogform
            setCatalogForm1={setCatalogForm1}
            CatalogName={"EZ_Parking_product_page"}
          />
        </>
      )}

      <div className="Schedule_Demo_retailytics1">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Download catalogue
            <img src={imageSrc} />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default EzparkingHubliDharwad;
