import React from "react";
import img1 from "../assets/Img1.svg";
import img2 from "../assets/Img2.svg";
import img3 from "../assets/Img3.svg";
import "./comingsoon.css";
import Header from "../Header/Header.js";

const Comingsoon = () => {
  return (
    <>
      <section>
        <Header />
        <div className="Block">
          <div className="Logo_Container">
            <div className="Image">
              <div className="Img1">
                <img className="Spin1" src={img1} alt="" loading="lazy" />
              </div>
              <div className="Img2">
                <img className="Spin" src={img2} alt="" loading="lazy" />
              </div>
              <div className="Img3">
                <img src={img3} alt="" loading="lazy" />
              </div>
            </div>
          </div>
          <div className="Heading_Container">
            <h1>404</h1>
          </div>
          <div className="Content_Container">
            <p>Page Not Found!</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Comingsoon;
