import React from "react";
import "../footfall/Footfall.css";
import { useState, useEffect, Suspense } from "react";
import Navigate from "../navigation/Navigate";
import purchase from "../../../../../assets/Retailyticspage/purchase.svg";
import { Helmet } from "react-helmet-async";
import Footer from "../footer1/Footer1";
import "../../../../../Landing Page/Vehicular Access Control/ANPR.css";
import India from "../../../../../assets/India.svg";
import Catalogform from "../../../../Catalog_Form/Catalogform";
import cloudDownload from "../../../../../assets/cloudDownload.svg";
import cloudDownload1 from "../../../../../assets/cloudDownload1.svg";
import { useContact } from "../../../../../context/ContactProvider";

const PeopleCounting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);

  const { setProductName } = useContact();

  useEffect(() => {
    setProductName("Retailytics - People Counting");
  }, []);

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Navigate />
      <Helmet>
        <title>
          People Counting Software Technology Solutions| Retail Visitor
          Analytics Sensor system
        </title>
        <meta
          name="description"
          content="Utilize our advanced people counting software technology solutions to gain accurate retail visitor analytics sensor system. Our technology provides real-time data on foot traffic, helping businesses optimize operations, improve customer experiences, and maximize efficiency. Discover the power of data-driven insights with our solutions."
        />
        <meta
          name="keyword"
          content=" people counting, people counting sensor, people counting system, retail people counter, people counting device, people counting cameras, people counting system, people counting software, people counting camera, people counting technology, people counting analytics, people counting solution, retail people counting"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com//Retailytics/people-counting"
        />
      </Helmet>
      <div className="wholediv1">
        <div className="contentsdivs">
          <div className="footfallimage">
            <img
              src={purchase}
              alt="purchase"
              className="face1"
              loading="lazy"
            />
          </div>
          <br />
          <br />
          <div className="para1">
            <h2>Footfall Analytics</h2>
            <p>
              One of the most important metrics for every retail store, this is
              built on our AI platform to give you the accurate results of
              visitors to your store excluding your staff. This is integrated
              with your existing IP-cameras making it a retrofit feature that
              includes demographics too i.e. male & female visitors with their
              count of footfall in to your stored with the time stamp. The data
              science integrated with this will give graphical reports of peak &
              off-peak footfall in to your stores, giving a tool for decision
              making related to increasing visitors to your store.
            </p>
            <br />
            <p>
              The heat map feature understands and reads visitor movement
              pattern in your store with peak and off-peak concentration of
              visitors to show the key impact points in the store. This will
              certainly help you to arrange your inventory display according to
              the movement pattern in the store, giving maximum exposure to all
              the inventory displayed in your store.
            </p>
            <ul>
              <li>
                <h4>People counting with demographics</h4>
              </li>
              <li>
                <h4>Heat map with people movement pattern recognition</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <button
          type="button"
          className="Catalog_button_mobile"
          onClick={() => {
            setCatalogForm1(true);
          }}
        >
          Dowload catalogue
          <img src={cloudDownload} alt="" />
        </button>
      </div>

      {catalogForm1 && (
        <>
          <Catalogform
            setCatalogForm1={setCatalogForm1}
            CatalogName={"Retailytics_product_page"}
          />
        </>
      )}

      <div className="Schedule_Demo_retailytics3">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Dowload catalogue
            <img src={imageSrc} alt="" />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Footer />
    </>
  );
};

export default PeopleCounting;
