import React from "react";
import "../billing/Billing.css";
import { useState, useEffect } from "react";
import Navigate from "../navigation/Navigate";
import { Helmet } from "react-helmet-async";
import billing from "../../../../../assets/Retailyticspage/billing.svg";
import Footer from "../footer1/Footer1";
import "../../../../../Landing Page/Vehicular Access Control/ANPR.css";
import India from "../../../../../assets/India.svg";
import Catalogform from "../../../../Catalog_Form/Catalogform";
import cloudDownload from "../../../../../assets/cloudDownload.svg";
import cloudDownload1 from "../../../../../assets/cloudDownload1.svg";
import { useContact } from "../../../../../context/ContactProvider";

const CrowdDetection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);

  const { setProductName } = useContact();

  useEffect(() => {
    setProductName("Retailytics - Crowd Detection");
  }, []);

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Navigate />
      <Helmet>
        <title>
          Crowd Detection Monitoring System | Enhance Public Safety & Management
        </title>
        <meta
          name="description"
          content=" Improve public safety with our advanced crowd detection monitoring solutions. Utilize real-time analytics to detect and manage crowd gatherings, prevent overcrowding, and ensure efficient event management. Explore our innovative solutions for safer, well-managed public spaces today"
        />
        <meta
          name="keyword"
          content="Crowd Detection Analytics, Crowd Detection Monitoring, Crowd Detection Management System, Crowd Detection System, Crowd Detection Monitoring System"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com//Retailytics/Crowd-detection"
        />
      </Helmet>
      <div className="wholediv2">
        <div className="contentsdiv1">
          <div className="billingimage">
            <img src={billing} alt="billing" className="face1" loading="lazy" />
          </div>
          <br />
          <br />
          <div className="para2">
            <h2>Billing counter analytics</h2>
            <p>
              While shopping is indeed an enjoyable experience, waiting in long
              serpentine queues in a horrid experience for customers. Most of
              the times especially in peak footfall times, the billing counter
              is overcrowded, leading to dissatisfaction among your customers.
            </p>
            <br />
            <p>
              Our billing counter analytics in constantly tracking the queue
              count, automatically calculating the time taken for each
              transaction and notifying the store manager to open the closed
              billing counters instantly through notifications and alerts. The
              alerts generated to the store managers if your staff is missing in
              the billing counters while customers are these counters.
            </p>
            <br />
            <p>
              This also creates an automated comprehensive report for key
              decision makers to aid them in decision making related to
              organizing the billing counters better for a good billing
              experience to their customers.
            </p>
            <ul>
              <li>
                <h4>No-staff at billing counter with instant alerts</h4>
              </li>
              <li>
                <h4>Queue counting in billing counters </h4>
              </li>
              <li>
                <h4>Overcrowding with alerts</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <button
          type="button"
          className="Catalog_button_mobile"
          onClick={() => {
            setCatalogForm1(true);
          }}
        >
          Dowload catalogue
          <img src={cloudDownload} alt="" />
        </button>
      </div>

      {catalogForm1 && (
        <>
          <Catalogform
            setCatalogForm1={setCatalogForm1}
            CatalogName={"Retailytics_product_page"}
          />
        </>
      )}

      <div className="Schedule_Demo_retailytics4">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Dowload catalogue
            <img src={imageSrc} alt="" />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Footer />
    </>
  );
};

export default CrowdDetection;
