import React from "react";
import "../../Pages/footer1/Footer1.css";
import { useLocation } from "react-router-dom";
import faceicon from "../../../../../assets/Retailyticspage/faceicon.svg";
import footicon from "../../../../../assets/Retailyticspage/footicon.svg";
import billingicon from "../../../../../assets/Retailyticspage/billingicon.svg";
import securityicon from "../../../../../assets/Retailyticspage/securityicon.svg";
import digitalicon from "../../../../../assets/Retailyticspage/digitalicon.svg";
import tabletop from "../../../../../assets/Retailyticspage/tabletop.svg";

const Footer1 = () => {
  const location = useLocation();

  return (
    <>
      <div className="footer">
        <nav>
          <div className="icons">
            <a
              href="/Retailytics/Face-Detection-Technology"
              className="icon-container"
            >
              <img
                src={faceicon}
                className={
                  location.pathname === "/Retailytics/Face-Detection-Technology"
                    ? "active"
                    : ""
                }
                alt="Face Icon"
                loading="lazy"
              />
              <span className="tooltip1">Face Recognition</span>
              <img
                src={tabletop}
                className={
                  location.pathname === "/Retailytics/Face-Detection-Technology"
                    ? "tabletop active-tabletop"
                    : "tabletop"
                }
                alt="Tabletop"
                loading="lazy"
              />
            </a>

            <a href="/Retailytics/people-counting" className="icon-container">
              <img
                src={footicon}
                className={
                  location.pathname === "/Retailytics/people-counting"
                    ? "active"
                    : ""
                }
                alt="Footfall Icon"
                loading="lazy"
              />
              <span className="tooltip1">Footfall analytics</span>
              <img
                src={tabletop}
                className={
                  location.pathname === "/Retailytics/people-counting"
                    ? "tabletop active-tabletop"
                    : "tabletop"
                }
                alt="Tabletop"
                loading="lazy"
              />
            </a>

            <a href="/Retailytics/Crowd-detection" className="icon-container">
              <img
                src={billingicon}
                className={
                  location.pathname === "/Retailytics/Crowd-detection"
                    ? "active"
                    : ""
                }
                alt="Billing Icon"
                loading="lazy"
              />
              <span className="tooltip1">Billing counter analytics</span>
              <img
                src={tabletop}
                className={
                  location.pathname === "/Retailytics/Crowd-detection"
                    ? "tabletop active-tabletop"
                    : "tabletop"
                }
                alt="Tabletop"
                loading="lazy"
              />
            </a>

            <a
              href="/Retailytics/Intrusion-detection"
              className="icon-container"
            >
              <img
                src={securityicon}
                className={
                  location.pathname === "/Retailytics/Intrusion-detection"
                    ? "active"
                    : ""
                }
                alt="Security Icon"
                loading="lazy"
              />
              <span className="tooltip1" id="securitytool">
                Security analytics
              </span>
              <img
                src={tabletop}
                className={
                  location.pathname === "/Retailytics/Intrusion-detection"
                    ? "tabletop active-tabletop"
                    : "tabletop"
                }
                alt="Tabletop"
                loading="lazy"
              />
            </a>

            <a
              href="/Retailytics/Retail-Digital-Transformation"
              className="icon-container"
            >
              <img
                src={digitalicon}
                className={
                  location.pathname ===
                  "/Retailytics/Retail-Digital-Transformation"
                    ? "active"
                    : ""
                }
                alt="Digital Transformation Icon"
                loading="lazy"
              />
              <span className="tooltip1" id="digitalimage">
                Digital Transformation
              </span>
              <img
                src={tabletop}
                className={
                  location.pathname ===
                  "/Retailytics/Retail-Digital-Transformation"
                    ? "tabletop active-tabletop"
                    : "tabletop"
                }
                alt="Tabletop"
                loading="lazy"
              />
            </a>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Footer1;
