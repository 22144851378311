import React from "react";
import "./Footer.css";
import logo from "../assets/Logo.svg";
import icon1 from "../assets/l.svg";
import icon2 from "../assets/f.svg";
import icon3 from "../assets/x.svg";
import icon4 from "../assets/i.svg";
import icon5 from "../assets/y.svg";

const Footer = () => {
  return (
    <footer>
      <div className="Footer_components">
        <div className="fline"></div>
        <div className="Image_holder">
          <img src={logo} alt="Okular Techminds" loading="lazy" />
        </div>
        <div className="Page_Holder">
          <span>
            <a href="/face-recognition-technology">Facesense</a>
            <a href="/Retail-Analytics-Solution">Retailytics</a>
            <a href="/automated-traffic-enforcement-system">
              Traffic Enforcement
            </a>
            <a href="/Ezparking">EZ Parking</a>

            {/* <a href="/vehicle-telematics">Vehicle Telematics</a> */}
          </span>
          <span>
            <a href="/smart-pole-solution">Smart Pole</a>
            <a href="/smart-poles">Mahila Suraksha</a>
            <a href="/perimeter-smart-security-system">Seema Suraksha</a>
            <a href="/suraksha-netra">Suraksha Netra</a>
            {/* <a href="/ambulance-signal-free-corridor">Signal Free Corridor</a> */}
          </span>
        </div>
      </div>
      <div className="Social_Holder">
        <div className="CopyPrivacy">
          <h3>
            © 2024 All rights reserved.{" "}
            <a href="/">
              <span>Okulr Techminds</span>
            </a>
          </h3>
          <h3>Privacy Policy . Terms & Conditions</h3>
        </div>
        <div className="Social_M">
          <a
            href="https://www.linkedin.com/company/okulr-techminds"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon1} alt="LinkedIn" loading="lazy" />
          </a>
          <a
            href="https://www.facebook.com/okulrtechmind"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon2} alt="Facebook" loading="lazy" />
          </a>
          <a
            href="https://twitter.com/OkulrTechminds"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon3} alt="X" loading="lazy" />
          </a>
          <a
            href="https://www.instagram.com/okulrtechminds"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon4} alt="Instagram" loading="lazy" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCjeEgq_bYdN4M5fdnluNmGg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={icon5} alt="Youtube" loading="lazy" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
