import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import cloudDownload from "../../assets/cloudDownload.svg";
import cloudDownload1 from "../../assets/cloudDownload1.svg";
import "./Page.css";
import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";
import India from "../../assets/India.svg";
import Catalogform from "../Catalog_Form/Catalogform";
import { useContact } from "../../context/ContactProvider";

const TrafficEnforcement = () => {
  const [catalogForm1, setCatalogForm1] = useState(false);
  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);

  const { setProductName } = useContact();

  useEffect(() => {
    setProductName("Automated Traffic Enforcement");
  }, []);

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
  const tabs = document.querySelector(".tabs");
  const playerRef = useRef(null);
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop < lastScrollTop) {
        if (tabs) {
          tabs.classList.remove("hidden");
        }
      }
      lastScrollTop = currentScrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleExitViewport = () => {
    try {
      if (playerRef.current.getInternalPlayer()) {
        playerRef.current.getInternalPlayer().pauseVideo();
      }
    } catch (error) {
      console.log("");
    }
  };
  const handleScroll = () => {
    const video = document.querySelector(".video_container");
    const tabs = document.querySelector(".tabs");
    video.scrollIntoView({ behavior: "smooth", block: "center" });
    setTimeout(() => {
      tabs.classList.add("hidden");
    }, 100);
  };
  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;
  return (
    <>
      <Helmet>
        <title>
          ITMS, Automated Road Traffic Safety Enforcement and eChallans |
          Enhancing Road Safety
        </title>
        <meta
          name="description"
          content=" Improve road safety with automated traffic enforcement solutions. Utilize advanced technology for efficient monitoring, enforcement, and deterrence of traffic violations. Explore our comprehensive solutions for safer roads today! "
        />
        <meta
          name="keyword"
          content="Traffic Safety Enforcement, Automated Road Traffic Safety, road safety enforcement, Enhancing Road Safety"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/automated-traffic-enforcement-system"
        />
      </Helmet>
      <section className="Productpage_Section">
        <div className="product_heading">
          <h2> Automated Traffic Enforcement System</h2>
        </div>
        <div className="product_details">
          <p>
            Managing burgeoning traffic today in Indian cities is indeed a
            gargantuan task for traffic police. With increasing vehicular
            population, enforcement is of utmost importance to increase road
            safety, and to save lives considering 4.61 lakhs accidents reported
            in 2022 in India as per data from MORTH. Using AI to regulate road
            traffic, increase automated smart enforcement and educate road
            safety to users is the need of the hour. ATES is our traffic
            enforcement product designed for India, by Indians, and proudly Made
            in India.
          </p>
        </div>
        <Waypoint onLeave={handleExitViewport}>
          <div className="video_container">
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=HJhxt8ix_wQ"
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              onPlay={() => {
                handleScroll();
              }}
            />
          </div>
        </Waypoint>
        <div className="About_product">
          <h3>About the Product</h3>
          <p>
            ATES encompasses all aspects of traffic enforcement starting from
            traffic violation detection & capture to issuing eChallans to
            traffic offenders on an automated AI platform. ATES is designed and
            developed for Indian traffic volume, scenarios and regulations. ATES
            works with multiple camera models and makes, making it a retrofit
            device ensuring our customers need not make new investments on their
            cameras. It integrates multiple cities, multiple cameras with
            multiple video analytics use cases into one single unified platform
            making it easier for our customers to manage their traffic
            management efficiently and easily.
          </p>
        </div>
        <div className="bulletpoints_container">
          <div className="bullet_point">
            <p>Traffic violation uses cases in ATES</p>
          </div>
          <div className="bullet_point">
            <p>Indian number plate recognition</p>
          </div>
          <div className="bullet_point">
            <p>No-helmet driving detection</p>
          </div>
          <div className="bullet_point">
            <p>Seat-belt violation</p>
          </div>
          <div className="bullet_point">
            <p>Over speeding detection</p>
          </div>
          <div className="bullet_point">
            <p>Traffic jump violation detection</p>
          </div>
          <div className="bullet_point">
            <p>No-parking zone violation</p>
          </div>
          <div className="bullet_point">
            <p>Vehicle type classification</p>
          </div>
          <div className="bullet_point">
            <p>Vehicle counting</p>
          </div>
          <div className="bullet_point">
            <p>Vehicle whitelisting and blacklisting</p>
          </div>
          <div className="bullet_point">
            <p>Wrong-way driving violation</p>
          </div>
          <div className="bullet_point">
            <p>Triple riding detection</p>
          </div>
          <div className="bullet_point">
            <p>Lane change alerts</p>
          </div>
          <div className="bullet_point">
            <p>Vaahan integration</p>
          </div>
          <div className="bullet_point">
            <p>eChallan integration</p>
          </div>
        </div>
        <div className="About_product">
          <p>
            With ATES traffic offenders can get eChallans as SMS messages with
            link to see their violation and also pay the fines with integrated
            payment gateway channels. The inbuilt data analytics keeps a track
            of repeat traffic offenders in each of the zones, ensuring the
            traffic police is aware of these offenders.
          </p>
        </div>
        <div className="About_product">
          <h3>Predictive Traffic Analytics</h3>
          <div>
            <p>
              This is an absolute need for major cities in India with traffic
              jams everywhere. Analysing traffic density and volumes, our
              predictive models in ATES helps commuters with advance information
              of traffic jams in key arterial roads in the city to avoid further
              clogging and helping them reroute themselves to reach their
              destinations on time. Traffic Sahay apps for commuters will help
              in knowing the best routes to take, traffic density information
              across major roads in the city and more. Predictive analytics
              gives police with abundant information about traffic volume
              patterns, peak and off-peak hours, routes and alternate rerouting
              and more for better traffic management and smoother traffic flow
              across the city.
            </p>
          </div>
        </div>
        <div className="features">
          <h3>Applications</h3>
          <div className="features_containt">
            <ul>
              <li>City roads</li>
              <li>Ring roads</li>
              <li>State highways</li>
              <li>National highways</li>
            </ul>
          </div>
        </div>
        {/* <div>
          <button
            type="button"
            className="download_button"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div> */}
        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Okulr_ANPR_Catalog"}
            />
          </>
        )}
      </section>
      <div className="Schedule_Demo">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Download catalogue
            <img src={imageSrc} />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
    </>
  );
};

export default TrafficEnforcement;
