import React, { useState } from "react";
import "../Faq/Faq.css";
import close from "../assets/facesense/close.svg";
import correct from "../assets/facesense/correct.png";
import wrong from "../assets/facesense/wrong.png";

function Faq() {
  const [openIndex, setOpenIndex] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const toggleAnswer = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const faqs = [
    {
      question: "Is Facesense one time purchase or subscription type?",
      answer:
        "It is subscription model i.e., you have to make annular payment calculated per employee/annum.",
      open: false,
    },
    {
      question: "Is it monthly or annual subscription?",
      answer: "Annual subscription i.e., you’ll pay per employee/annum.",
      open: false,
    },
    // {
    //     question: "How is Facesense charged and what is the subscription charge?",
    //     answer: "It is charged per employee/annum. License subscription ranges from INR 640 to 785/- per employee/annum plus GST.",
    //     open: false,
    // },
    {
      question: "How do I pay for subscription charges?",
      answer: "Through Bank/UPI/Card payments.",
      open: false,
    },
    {
      question: "Is internet mandatory to use Facesense?",
      answer:
        "Yes, it is. Broadband internet to connect to the Facesense console on cloud is required.",
      open: false,
    },
    {
      question:
        "What is the failover mechanism if Internet fails at my premises?",
      answer:
        "During internet failover, data accumulation happens at the edge i.e., your camera or desktop. When internet resumes, it replicates automatically in your central console.",
      open: false,
    },
    {
      question: "What type of camera is compatible for using Facesense?",
      answer:
        "It must be network/IP cameras, minimum 5MP, IR 25/30 mtrs, edge storage of minimum 256GB with all other standard specifications.",
      open: false,
    },
    {
      question:
        "What is the configuration of the desktop required for Facesense Lite application?",
      answer:
        "Intel i5/i7, Windows 11 OS, minimum 16GB RAM, 1TB HDD/SSD, with all other specifications.",
      open: false,
    },
    {
      question: "Will my attendance be captured if I don't see the camera?",
      answer:
        "No. You'll have to see the camera for a second for accurate attendance to be captured.",
      open: false,
    },
    {
      question:
        "Can multiple people i.e., 10 to 20 people be recognised accurately at one shot?",
      answer:
        "NO. This is built on our neural compute platform which is an AI platform. For attendance, recognition must be 100% accurate which means clear capture of face from the camera. The maximum it can handle is upto 3-4 people in one single frame.",
      open: false,
    },
    {
      question:
        "What if I change the camera at a later date? How do I use this new camera to Facesense application?",
      answer:
        "Facesense Lite application has the features to add entry and exit cameras as per your need. When you change the camera, simply add the new camera in the Facesense Lite application.",
      open: false,
    },
    {
      question:
        "Can I access FATS application to track employee attendance and its reports from anywhere, anytime?",
      answer:
        "Yes, the authorised personnel in your company can access this from anywhere, anytime as this works on the cloud.",
      open: false,
    },
    {
      question:
        "Can I use Facesense at my premises at different locations and monitor the employee attendance and its reports from a single location?",
      answer:
        "Yes, as long as you have multi-license model you can connect different branches at different locations on a unified locations.",
      open: false,
    },
    {
      question: "Can Facesense recognise people wearing masks?",
      answer: (
        <>
          Yes, it can recognise if you wear mask as per the standards mentioned.{" "}
          <a
            onClick={() => setShowPopup(true)}
            style={{ color: "#368acf", textDecoration: "underline" }}
          >
            {" "}
            Check the standards here.
          </a>
        </>
      ),
    },
    {
      question: "Can Facesense work in pitch dark scenario?",
      answer: "No, there must be enough lighting to recognise the face.",
      open: false,
    },
    {
      question:
        "What are the stipulations for installing the camera for accurate recognition of face in Facesense?",
      answer:
        "Camera must be installed at 6ft height on the wall. The distance for recognition is 2ft to 4ft from the camera. There must be good lighting in the location for clear face capture. Camera must not be installed in sunlight falling directly on the camera lens.",
      open: false,
    },
  ];
  return (
    <div className="faq-container-fs">
      {faqs.map((faq, index) => (
        <div className="faq-item-fs" key={index}>
          <div
            className="Query-fs"
            onClick={() => toggleAnswer(index)}
            style={{ borderBottomColor: openIndex === index ? "#0F0" : "#AAA" }}
          >
            <div className="question-fs">
              {index + 1}. {faq.question}
              <div className="SVG-fs">
                <svg
                  className={`arrow-fs ${
                    openIndex === index ? "open" : "close"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="46"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.2266 0.0637427C12.4125 0.0635084 12.5967 0.0999646 12.7686 0.171024C12.9404 0.242083 13.0966 0.34635 13.2281 0.477851C13.3596 0.609353 13.4639 0.765504 13.5349 0.937364C13.606 1.10922 13.6424 1.29341 13.6422 1.47938L13.6402 10.0632L22.224 10.0612C22.5995 10.0612 22.9595 10.2104 23.225 10.4758C23.4905 10.7413 23.6397 11.1014 23.6397 11.4768C23.6397 11.8523 23.4905 12.2124 23.225 12.4779C22.9595 12.7433 22.5995 12.8925 22.224 12.8925L13.6402 12.8905L13.6422 21.4743C13.6422 21.8498 13.4931 22.2098 13.2276 22.4753C12.9621 22.7408 12.602 22.8899 12.2266 22.8899C11.8511 22.8899 11.491 22.7408 11.2256 22.4753C10.9601 22.2098 10.8109 21.8498 10.8109 21.4743L10.8129 12.8905L2.2291 12.8925C2.0432 12.8925 1.85912 12.8559 1.68736 12.7847C1.51561 12.7136 1.35955 12.6093 1.2281 12.4779C1.09664 12.3464 0.992365 12.1903 0.921222 12.0186C0.85008 11.8468 0.813463 11.6627 0.813463 11.4768C0.813463 11.2909 0.850079 11.1069 0.921222 10.9351C0.992365 10.7633 1.09664 10.6073 1.22809 10.4758C1.35955 10.3444 1.51561 10.2401 1.68736 10.169C1.85912 10.0978 2.0432 10.0612 2.2291 10.0612L10.8129 10.0632L10.8109 1.47938C10.8107 1.29341 10.8471 1.10922 10.9182 0.937362C10.9893 0.765503 11.0935 0.609352 11.225 0.477851C11.3565 0.34635 11.5127 0.242084 11.6845 0.171025C11.8564 0.099965 12.0406 0.0635086 12.2266 0.0637427Z"
                    fill="#AAA"
                  />
                </svg>
              </div>
            </div>
            <div
              className={`answer-fs ${openIndex === index ? "open" : "close"}`}
            >
              {faq.answer}
            </div>
          </div>
          {showPopup && (
            <>
              <div className="Overlay-fs" />
              <div className="Popup-fs">
                <img
                  src={close}
                  alt="Close"
                  className="CloseButton-fs"
                  onClick={() => setShowPopup(false)}
                  loading="lazy"
                />

                <div className="top-bar-fs">
                  <h1>Face Recognition standards with mask</h1>
                </div>
                <br />
                <div className="std-img-fs">
                  <img src={correct} alt="correct" loading="lazy" />
                  <img src={wrong} alt="wrong" loading="lazy" />
                </div>
              </div>
            </>
          )}

          {/* <svg
                        className={`arrow-fs ${openIndex === index ? "open" : "close"}`}
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="20.5" stroke="#2E2E2E" />
                        <path d="M21 30C20.2801 30 19.6966 29.4164 19.6966 28.6966V13.3034C19.6966 12.5836 20.2801 12 21 12C21.7199 12 22.3034 12.5836 22.3034 13.3034V28.6966C22.3034 29.4164 21.7199 30 21 30ZM13.2524 22.2736C12.5607 22.2736 12 21.7129 12 21.0212C12 20.3296 12.5607 19.7689 13.2524 19.7689H28.7476C29.4393 19.7689 30 20.3296 30 21.0212C30 21.7129 29.4393 22.2736 28.7476 22.2736H13.2524Z" fill="#2E2E2E" />
                    </svg> */}
        </div>
      ))}
    </div>
  );
}

export default Faq;
