import React from "react";
import { useState } from "react";
import "../../Landing Page/Retailytics/Retailyticspages.css";
import retailytics from "../../assets/Retailyticspage/retailytics.jpg";
import people from "../../assets/Retailyticspage/people.svg";
import purchase from "../../assets/Retailyticspage/purchase.svg";
import billing from "../../assets/Retailyticspage/billing.svg";
import security from "../../assets/Retailyticspage/security.svg";
import AnimationComponent from "../../Products/ProductPages/Retailyticspage/animation/animation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../Landing Page/Vehicular Access Control/ANPR.css";
import India from "../../assets/India.svg";
import Catalogform from "../../Products/Catalog_Form/Catalogform";
import cloudDownload from "../../assets/cloudDownload.svg";
import cloudDownload1 from "../../assets/cloudDownload1.svg";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Form from "../../Form/Form";

const RetailyticsMysore = () => {
  const [buttonImage, setButtonImage] = useState(cloudDownload1);
  const [isHovered, setIsHovered] = useState(false);
  const [catalogForm1, setCatalogForm1] = useState(false);
  const navigate = useNavigate();

  const handleCatalogClick = () => {
    setCatalogForm1(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const imageSrc = isHovered ? cloudDownload : buttonImage;

  const handleDemoScroll = () => {
    const scroll = document.querySelector(".ScrollTo");
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  function Face() {
    navigate("/Retailytics/Face-Detection-Technology");
  }

  function Foot() {
    navigate("/Retailytics/people-counting");
  }

  function Billing() {
    navigate("/Retailytics/Crowd-detection");
  }

  function Security() {
    navigate("/Retailytics/Intrusion-detection");
  }

  function Clicking() {
    navigate("/Retailytics/Retail-Digital-Transformation");
  }

  return (
    <>
      <Helmet>
        <title>
          Smart Retail Anaytics Software Company Solutions in Mysore & Platfform
          For CustomerExperience Services
        </title>
        <meta
          name="description"
          content="Transform your instore operations with our Smart retail analytics software solutions in Mysore. Gain real-time insight into customer behaviour Video, optimize store layouts, and enhance sales strategies with advanced data analytics. Discover how our company platform can boost efficiency and customer satisfaction services today."
        />
        <meta
          name="keyword"
          content="Retail Analytics in Mysore, retail store analytics in Mysore, retail data Analytics in Mysore, Retail Analytics software in Mysore, Retail Analytics Company in Mysore, Store Analytics in Mysore, Retail Analysis in Mysore, Retail Video Analytics in Mysore, Retail Analytics platforms in Mysore, retail Analytics Solutions, instore Analytics in Mysore, Retail Analytics Services in Mysore"
        />
        <link
          rel="canonical"
          href="https://www.okulr.com/Retail-Analytics-Solution-Mysore"
        />
      </Helmet>
      <Header />
      <div className="main_contents1">
        <div className="heading1">
          <h1>Visual data driven tool for your retail store</h1>
          <p>
            <strong>Hey Mysore!</strong> Digital transformation in the key to
            success in the brick & mortar retail industry today. Syncronising
            new technology seamlessly to create better customer experiences,
            optimize business processes, enable retail workers, and build more
            agile, growth-oriented business models is inevitable to compete with
            the eCommerce.
          </p>
        </div>
        <div className="picture">
          <img
            src={retailytics}
            className="retailytics"
            alt="retailytics"
            loading="lazy"
          />
        </div>
        <div className="about_product">
          <h1>About the Product</h1>
          <p>
            Welcome to Retailytics, where technology meets real world shopping
            to make business sense of retail stores to give the best shopping
            experience for customers. We bring emerging technologies like AI,
            AR/VR, visual analytics transforming to business analytics, mobile
            computing, big data to transform retail operations and models to
            sustain growth, keep up with competitors, provide better customer
            experiences, and continue to innovate.
          </p>
        </div>
        <div className="top_cards">
          <div className="left_card1">
            <h2>Face recognition</h2>
            <ul>
              <li>
                <p>Employees - Attendance and logs</p>
              </li>
              <li>
                <p>Customers - Customer loyalty program</p>
              </li>
            </ul>
            <img src={people} alt="people" id="people" loading="lazy"></img>
            <br />
            <input
              type="button"
              id="know_more"
              value="Know More"
              onClick={Face}
            />
          </div>
          <div className="right_card1">
            <h2>Footfall analytics</h2>
            <ul>
              <li>
                <p>People counting with demographics</p>
              </li>
              <li>
                <p>Heat map with people movement pattern recognition</p>
              </li>
            </ul>
            <img
              src={purchase}
              alt="purchase"
              id="purchase"
              loading="lazy"
            ></img>
            <br />
            <input
              type="button"
              id="know_more"
              value="Know More"
              onClick={Foot}
            />
          </div>
        </div>
        <br />
        <div className="bottom_cards">
          <div className="left_card2">
            <h2>Billing counter analytics</h2>
            <ul>
              <li>
                <p>No-staff at billing counter with instant alerts</p>
              </li>
              <li>
                <p>
                  Queue counting in billing counters & overcrowding with alerts
                </p>
              </li>
            </ul>
            <img src={billing} alt="billing" id="billing" loading="lazy"></img>
            <br />
            <input
              type="button"
              id="know_more"
              value="Know More"
              onClick={Billing}
            />
          </div>
          <div className="right_card2">
            <h2>Security analytics</h2>
            <ul>
              <li>
                <p>Intrusion & breach detection with alerts </p>
              </li>
              <li>
                <p>Person tracking within the store</p>
              </li>
              <li>
                <p>Security patrol tracking system</p>
              </li>
            </ul>
            <img
              src={security}
              alt="security"
              id="security"
              loading="lazy"
            ></img>
            <br />
            <input
              type="button"
              id="know_more"
              value="Know More"
              onClick={Security}
            />
          </div>
        </div>
        <br />
      </div>

      <div className="customer">
        <div className="customer_experience">
          <h2>Digital Transformation to focus on customer experience</h2>
          <p>
            Mobile brings in inclusivity in technology irrespective of the
            sectors. Everyone today around the world are used to shopping with
            their mobile phones. Plugging in this makes the retail store
            shopping easier, exciting and a pleasant experience for customers i
            a crowded store.
          </p>
        </div>
        <br />

        <div className="customer_content">
          <div className="content_left">
            <p>
              The retail industry is a $7T global market employing 1.8B+ people.
              Additionally, there are 3.2B consumers worldwide who shop online.
              Gone are the times when retailers created value and earned loyalty
              only through factors like competitive pricing, store proximity,
              convenience, and variety. The current times are different from the
              one-size-fits-all era of communications and promotions.
              Personalization in communications and marketing tactics is
              necessary to retain customers and bring in new ones. Retailers can
              now use data and insights to increase the frequency of purchases
              of their existing customers and target high-value customers of
              traditional retailers. The demand for digital transformation in
              the retail industry has increased due to it’s potential to drive
              revenue and growth for retailers.
            </p>
            <br />
            <input
              type="button"
              id="digital"
              value="Digital Transformation of Retail Stores"
              onClick={Clicking}
            />
          </div>
          <div className="content_right">
            <AnimationComponent />
          </div>
        </div>
        <div className="retailytics_catalog">
          <button
            type="button"
            className="Catalog_button_mobile"
            onClick={() => {
              setCatalogForm1(true);
            }}
          >
            Dowload catalogue
            <img src={cloudDownload} alt="" />
          </button>
        </div>

        {catalogForm1 && (
          <>
            <Catalogform
              setCatalogForm1={setCatalogForm1}
              CatalogName={"Retailytics_product_page"}
            />
          </>
        )}
      </div>

      <div className="Schedule_Demo_retailytics1">
        <div className="two-buttons">
          <button className="Demo_button_1" onClick={handleDemoScroll}>
            Schedule a demo
          </button>
          <button
            type="button"
            className="Catalog_button"
            onClick={handleCatalogClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Download catalogue
            <img src={imageSrc} alt="" />
          </button>
        </div>
        <div className="Made_In_India">
          <h2>Proudly Made in India</h2>
          <img src={India} alt="India" />
        </div>
        <div className="ScrollTo"></div>
      </div>
      <Form />
      <Footer />
    </>
  );
};

export default RetailyticsMysore;
